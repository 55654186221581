import React, { useState, useRef } from "react";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import { useEffect } from "react";
import axios from "axios";
import { Toast } from "primereact/toast";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { TabPanel, TabView } from "primereact/tabview";
import { useLocation} from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";


const ViewCompDetails = () => {  debugger;
  
  const location = useLocation();    
  const Btntype = location.state?.Btntype;
  const visibility = location.state?.visibility;
  const navigate = useNavigate();
  const [detailsDataAray, setData] = useState([]);
  ///const [sectionData,setSectionData]=useState([]);
  const access_token = localStorage.getItem("accessToken");
  const toast = useRef(null);
  const userRole = localStorage.getItem("userRole")!=null>0?localStorage.getItem("userRole"):'';
  const [ifReloaded, setIfReloaded] = useState(false);
  const loggedInUser = localStorage.getItem("loggedInUser");
  var DetailsData, DetailsDataArray2, componentEditable, sectionData;
  var userRoleList = userRole!=null?userRole.split(","):'';
  var adminUser = userRoleList!=null?userRoleList.indexOf("ADMIN"):'';
  var nonAdminUser = userRoleList!=null?userRoleList.indexOf("NONEXPERT"):'';
  var compVisibility; //props.compData.visible_to_all;
  const [editedData, setEditedData] = useState({});
  const [validationErrors, setValidationErrors] = useState({});
  const [RevisionMsg, setRevisionMsg] = useState("");
  const [showRevisionMsg, setshowRevisionMsg] = useState(false);
  const [componentList, setComponentList] = useState([]);
  const [compData, setCompData] = useState(null);  
  const path = window.location.pathname;
  const pathParts = path.split("/");

  useEffect(() => {}, [validationErrors]);
  //let compData;

  useEffect(() => {
    if (
      localStorage.getItem("accessToken") != null &&
      localStorage.getItem("accessToken") != "" &&
      sessionStorage.getItem("detail_data")
    ){
      
    } else {
      navigate("/login");
      localStorage.setItem("RequestedLink",path)
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {  
    const fetchData = async () => {  
      let data;  
      if (location.state) {  
        data = location.state.compData;  
      } else {  
        data = await getComponentList(100, true);  
      }  
      setCompData(data);  
    };  
    
    fetchData();  
  }, []);  
  
  
  const getCompIDFromURL = () => {    debugger;
    
    const compID = pathParts[pathParts.length - 1];
    return compID;
  };

  const getComponentList = async (pageSize, pageOrder) => {
    debugger;
    const token = access_token;
    const compID = getCompIDFromURL();
    const response = await axios.post(
      "https://esd-api.mosaic-dev.siemens-energy.cloud/api/v1/dev/compV2/get-comp-filterV2",
      {
        comp_filter_schema: {
          search: "",
          comp_view: "ALL",
          filters: [],
          types: "",
          page: 1,
          size: 100, //pageSize,
          order: pageOrder,
        },
      },
      { headers: { Authorization: `Bearer ${token}` } }
    );
    const res=response.data.filter((item) => item.id == compID);    
    return res[0];

  };

  

  //compData = location.state?location.state.compData:  
  

  // compData = location.state?location.state.compData:
  // getComponentList(100,true);

 
  const isComponentEditable = () => {
    if (Btntype === "copy") {
      componentEditable = true;
    } else if (adminUser >= 0) {
      componentEditable = true;
    } else if (nonAdminUser >= 0) {
      if (compData["created_by"] === loggedInUser) {
        componentEditable = true;
      } else {
        componentEditable = false;
      }
    } else {
      componentEditable = false;
    }
  };

  const [selectedStatus, setSelectedStatus] = useState(null);

  let Status, sectionOrder,detailDataKeysHash;

  if (compData) {
    debugger;
    const {
      sections,
      comp_id,
      updated_at,
      updated_by,
      hidden,
      hidden_at,
      hidden_by,
      created_by_elevated_user,
      revision_generated,
      revision,
      id,
      visible_to_all,
      ...selecteddd
    } =compData;
    DetailsData = selecteddd;
    DetailsDataArray2 = Object.entries(DetailsData);

    //sectionData=Object.entries(props.compData.sections);
      sectionData = sections;
      const sectiondd=
        sessionStorage.getItem("detail_data_section_ordered")
      ?JSON.parse(
        sessionStorage.getItem("detail_data_section_ordered")
      ):{}

      //sorted data as per sectionOrder
      const sectionOrder = sectiondd[compData.type]||[];
      sectionOrder.push("default");

      const sortedSections = sectionData  
      ? Object.keys(sectionData).sort((a, b) => {  
          return sectionOrder.indexOf(a) - sectionOrder.indexOf(b);  
        })  
      : [];  

    const sortedSectionData = {};  
    sortedSections.forEach((section) => {  
      sortedSectionData[section] = sectionData[section];  
    });  

      const detailDataKeysHash = JSON.parse(
        sessionStorage.getItem("detail_data")
      );

      sectionData = sortedSectionData;
      // const dataWithNames = Object.entries(sortedSectionData).map(([section, sectionData]) => {
    //   debugger;
    //   const updatedSectionData = Object.entries(sectionData).map(([key, value]) => {
    //     const foundData = detailDataKeysHash.find(
    //       (data) => data.key === key
    //     );
    //     return {
    //       id: key,
    //       value: value,
    //       name: foundData.name || "",
    //     };
    //   });

    //   return {
    //     section: section,
    //     data: updatedSectionData,
    //   };
    // });
    // sectionData=dataWithNames;
    // console.log("data with names",sectionData['General Information']['lib_gi_country']);

    isComponentEditable();
    compVisibility = compData.visible_to_all;
    Status = [
      compVisibility == true ? { name: "Prepared" } : { name: "Released" },
    ];
  }

  const textEditor = (options) => { debugger;
    return (
      <InputText
        type="text"
        value={options.value}
        onChange={(e) => options.editorCallback(e.target.value)}
      />
    );
  };
  
  const cellEditor = (options) => {
    return textEditor(options);
  };

  const onRowEditComplete2 = (e) => {
    debugger;
    let { index, data, newData, field, originalEvent: event } = e;
    data[1] = newData[1];
    DetailsDataArray2[index][1] = newData[1];
  };

  const handleRowEdit = (e, section) => {
    debugger;

    let { index, data, newData, field, originalEvent: event } = e;
    const errors = validateData(newData);
    if (Object.keys(errors).length > 0) {
      setValidationErrors({ [section]: { [data[0]]: errors } });
    } else {
      data[1] = newData[1];
      let sectionKey = data[0];
      sectionData[section][`${sectionKey}`] = newData[1];
      //Object.entries(sectionData)[index][1][sectionKey]=newData[1];

      setValidationErrors({});
    }
  };

  const getMinval = (val) => {
    const rangeRegex = /DBL\((>=\d+)?(,<=\d+)?\)/;

    const matches = val.match(rangeRegex);
    if (matches) {
      const min = matches[1] ? matches[1].substring(2) : "";
      return min;
    }
  };
  const getMaxVal = (val) => {
    const rangeRegex = /DBL\((>=\d+)?(,<=\d+)?\)/;

    const matches = val.match(rangeRegex);
    if (matches) {
      const max = matches[2] ? matches[2].substring(3) : "";
      return max;
    }
  };

  const validateData = (rowData) => {
    debugger;
    // Perform your validation logic here
    const errors = {};

    const detailDataKeysHash = JSON.parse(
      sessionStorage.getItem("detail_data")
    );
    const foundData = detailDataKeysHash.find(
      (data) => data.key === rowData[0]
    );
    const isMendatoryData = foundData.mandatory.includes("REQ") ? "Y" : "N";
    const iteminfo = foundData.param_entry_info;

    if (isMendatoryData && rowData[1].value == "") {
      errors.name = "Value is required.";
    }
    if (iteminfo.includes("DBL")) {
      const min = getMinval(iteminfo);
      const max = getMaxVal(iteminfo);
      if (isNaN(rowData[1])) {
        return (errors.name = "Value must be a number.");
      } else if (min != undefined) {
        if (max != "") {
          debugger;
          if (Number(rowData[1]) < min) {
            return (errors.name = `Value must be greater than ${min}`);
          } else if (!(Number(rowData[1]) > min && Number(rowData[1]) < max)) {
            return (errors.name = `Value must be between ${min} and ${max}`);
          }
        }
      }
    }
    // Example validation: Value should be a number

    return errors;
  };

  const createJSONDetails = () => {};
  const updateComponent = (e) => {
    debugger;

    const token = access_token;

    axios
      .put(
        "https://esd-api.mosaic-dev.siemens-energy.cloud/api/v1/dev/compV2/update-compV2/" +
          compData.id,
        {
          object: {
            type: compData.type,
            sections: sectionData,
            visible_to_all: false,
          },
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Component is Updated.",
          life: 2000,
        });
        return new Promise((resolve) => setTimeout(resolve, 2000));
      })
      .then(() => {
        navigate("/dashboard");
      });
  };

  const createDuplicateComp = () => {
    debugger;
    setshowRevisionMsg(false);
    if (RevisionMsg) {
      const token = access_token;

      // Handle saving data for each tab
      if (Object.entries(sectionData).length > 0) {
        let RequestData = {
          Compv2: {
            type: compData.type,
            //sections: data.tabs,
            sections: sectionData,
            visible_to_all: compData.visible_to_all,
          },
        };
        const JsonRequest = JSON.stringify(RequestData);
        console.log(JsonRequest);

        const token = access_token;
        axios
          .post(
            "https://esd-api.mosaic-dev.siemens-energy.cloud/api/v1/dev/compV2/create-revision/" +
              compData.id +
              "?revision_msg=" +
              RevisionMsg,
            {
              compV2: {
                type: compData.type,
                sections: sectionData,
                visible_to_all: compData.false,
              },
            },
            {
              headers: { Authorization: `Bearer ${token}` },
              "content-type": "text/json",
            }
          )
          .then((response) => {
            toast.current.show({
              severity: "success",
              summary: "Successful",
              detail: "Duplicate Component Created",
              life: 2000,
            });
            return new Promise((resolve) => setTimeout(resolve, 2000));
          })
          .then(() => {
            navigate("/dashboard");
          })
          .catch((error) => console.log("error", error));
      }
    }
  };

  const onClick = () => {
    debugger;
    createDuplicateComp();
  };

  const accept = (status) => {
    debugger;

    const token = access_token;

    axios
      .put(
        "https://esd-api.mosaic-dev.siemens-energy.cloud/api/v1/dev/compV2/update-compV2/" +
          compData.id,
        {
          object: {
            type: compData.type,
            sections: sectionData,
            visible_to_all:
              status == "Released" ? true : status == "Prepared" ? false : "", //compVisibility === true ? false : true,
          },
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Visibility has been changed.",
          life: 3000,
        });
      })
      .then(() => {
        setTimeout(() => {
          navigate("/dashboard");
        }, 3000);
      });
  };

  const reject = () => {
    toast.current.show({
      severity: "warn",
      summary: "Rejected",
      detail: "You have rejected",
      life: 3000,
    });
  };

  const renderNameColumnEditMode = (rowData) => {
    const detailDataKeysHash = JSON.parse(
      sessionStorage.getItem("detail_data")
    );
    
    const foundData = detailDataKeysHash.find(
      (data) => data.key === rowData[0]
    );
    return (
      <div>
        {foundData ? (
          <div dangerouslySetInnerHTML={{ __html: foundData.name }}></div>
        ) : (
          ""
        )}
      </div>
    );
  };

  const renderNameColumn = (rowData, index, section) => {
    const currentErrors = validationErrors[section]?.[rowData[0]];
    const hasErrors = currentErrors && Object.keys(currentErrors).length > 0;

    detailDataKeysHash = JSON.parse(
      sessionStorage.getItem("detail_data")
    );
    
    const foundData = detailDataKeysHash.find(
      (data) => data.key === rowData[0]
    );

    //return foundData ? foundData.name : '';
    return (
      <div>
        {foundData ? (
          <>
            <div
              style={{ fontSize: "15px" }}
              dangerouslySetInnerHTML={{ __html: foundData.name }}
            ></div>
            <small
              dangerouslySetInnerHTML={{ __html: foundData.description }}
            ></small>
          </>
        ) : (
          ""
        )}
        {hasErrors && (
          <div style={{ color: "red" }}>{Object.values(currentErrors)}</div>
        )}
      </div>
    );
  };

  const UpdateVisibility = (status) => {
    debugger;
    confirmDialog({
      message: `Currently this Component is ${
        compVisibility === true
          ? "Released and visible to all"
          : "Prepared and not visible to all"
      }
      Are You sure you want to change its Status?`,
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: () => accept(status.name),
      reject,
    });
  };

  const handleCancel = () => {
    setshowRevisionMsg(false);
  };

  const copyDataToClipboard = (rowData) => {
    debugger;
    const dataToCopy = JSON.stringify(rowData[1]); // Replace this with the actual data you want to copy

    // Create a temporary textarea to copy the data
    const tempTextArea = document.createElement("textarea");
    tempTextArea.value = dataToCopy.replace(/["']/g, "");
    document.body.appendChild(tempTextArea);

    // Select and copy the text
    tempTextArea.select();
    document.execCommand("copy");

    // Clean up
    document.body.removeChild(tempTextArea);

    toast.current.show({
      severity: "success",
      summary: "Data Copied",
      detail: "Data has been copied to clipboard.",
      life: 3000,
    });
  };

  const actionTemplate = (rowData) => {
    return (
      <i class="fa fa-copy" onClick={() => copyDataToClipboard(rowData)}></i>
    );
  }; 

  const CopyButtonEditor = ({ rowData, copyDataToClipboard }) => {  
    return (  
      <i  
        className="fa fa-copy"  
        onClick={() => copyDataToClipboard(rowData)}  
      ></i>  
    );  
  };  

  const renderValueColumn = (rowData) => {
    const fieldName = rowData[0]; // Assuming rowData[0] holds the value of item[0]

    if (fieldName === "hi_operation_point") {
      const detailDataKeysHash = JSON.parse(
        sessionStorage.getItem("detail_data")
      );
      const foundtablecols = detailDataKeysHash.find(
        (data) => data.key === fieldName
      );
      const cols = foundtablecols.table_cols;
      const columns = cols.map((header) => ({
        field: header,
        header: header,
      }));

      const mappedData = rowData[1].map((row) => {
        return row.reduce((acc, value, index) => {
          acc[cols[index]] = value;
          return acc;
        }, {});
      });

      const innerData = mappedData; // Assuming rowData[1] holds the data for the inner table
      return (
        <div style={{ marginTop: "1rem" }}>
          <DataTable value={innerData}>
            {/* Define your inner data table columns here */}
            {columns.map((col, index) => (
              <Column key={index} field={col.field} header={col.header} />
            ))}
          </DataTable>
        </div>
      );
    } else {
      // Render the value for rows that don't have "hi_operation_point"
      return (
        <div class="text-left" style={{ marginTop: "1rem" }}>
          {" "}
          {rowData[1]}
        </div>
      );
    }
  };

  return compData ? (
    <>
      <Toast ref={toast} />
      <div className="container" style={{ marginTop: "3vh" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <label className="siemens-text font-weight-bold">
            Component Details
          </label>  
          <div style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "right",
          }}>        
          {/* <h3><span class="badge badge-secondary mr-3 mt-2" style={{backgroundColor:"#8a00e5"}}>{adminUser?"ADMIN":"NON ADMIN"}</span></h3> */}
          <Dropdown
            value={selectedStatus}
            onChange={(e) => UpdateVisibility(e.value)}
            options={Status}
            optionLabel="name"
            placeholder="Change Status to.."
            className="w-full md:w-14rem"
          />
          </div>
          {/* <Button
            className="primaryBtn mr-2"
            onClick={UpdateVisibility}
            hidden={adminUser >= 0 && Btntype == "normal" ? false : true}
          >
            Change Status
          </Button> */}
        </div>
        <Dialog
          visible={showRevisionMsg}
          onHide={handleCancel}
          message="Please Provide Revision Comments?"
          header="Confirmation"
          modal
          footer={
            <div>
              {/* <Button label="Cancel" icon="pi pi-times" onClick={handleCancel} className="p-button-text" /> */}
              <Button
                label="OK"
                icon="pi pi-check"
                onClick={onClick}
                className="p-button-text"
                autoFocus
              />
            </div>
          }
        >
          <div className="p-fluid">
            <div className="p-field">
              <label htmlFor="valueInput">Please Enter Revision Comments</label>
              <input
                id="valueInput"
                type="text"
                value={RevisionMsg}
                onChange={(e) => setRevisionMsg(e.target.value)}
              />
            </div>
          </div>
        </Dialog>
        <ConfirmDialog />

        {/* <Button
                className="primaryBtn mt-2"
                onClick={UpdateVisibility}
                hidden={adminUser >= 0 ? false : true}
              >
                Change Status
              </Button> */}

        {/* <Dialog
        header={
          <div class="row">
            <div class="column">
              <label className="p-dialog-title">Component Details</label>
            </div>
            <div class="column">
              <ConfirmDialog />
              <Button
                className="primaryBtn mt-2"
                onClick={UpdateVisibility}
                hidden={adminUser >= 0 ? false : true}
              >
                Change Status
              </Button>
            </div>
          </div>
        }
        visible={visibility}
        style={{ width: "80vw" }}
        //footer={props.renderFooter("displayBasic")}
        //onHide={() => props.onHide("displayBasic", { ifReloaded })}
      > */}
        <div className="datatable-editing-demo">
          <div className="p-fluid">
            <DataTable
              value={DetailsDataArray2}
              //editMode="row"
              responsiveLayout="scroll"
              onRowEditComplete={onRowEditComplete2}
            >
              <Column
                field="0"
                header="Description"
                sortable
                style={{ minWidth: "16rem", fontSize: "14px" }}
              ></Column>
              <Column
                field="1"
                header="Value"
                //editor={(options) => textEditor(options)}
                sortable
                style={{ minWidth: "16rem", fontSize: "14px" }}
              ></Column>
              <Column
                hidden={componentEditable ? false : true}
                rowEditor
                headerStyle={{ width: "10%", minWidth: "8rem" }}
                bodyStyle={{ textAlign: "center" }}
              ></Column>
            </DataTable>
          </div>
        </div>
        <br></br>
        {sectionData && (
        <TabView>
          {Object.entries(sectionData).map((item, index) => {
            console.log(Object.entries(item[1]));
            return (
              <TabPanel
                key={index}
                header={item[0].charAt(0).toUpperCase() + item[0].slice(1)}
              >
                <DataTable
                  value={Object.entries(item[1])}
                  editMode="row"
                  responsiveLayout="scroll"
                  onRowEditInit={(e) => {
                    setEditedData(e.data);
                  }}
                  onRowEditComplete={(event) => {debugger;
                     handleRowEdit(event, item[0])}}
                  rowEditorValidator={(rowData) => validateData(rowData)}
                  editable={!validationErrors[item[0]]}
                >
                  <Column
                    //field="0"
                    body={(rowData) =>
                      renderNameColumn(rowData, rowData.index, item[0])
                    }
                    editor={(props) => {
                      const rowData = props.rowData;
                      const detailDataKeysHash = JSON.parse(
                        sessionStorage.getItem("detail_data")
                      );
                      const foundData = detailDataKeysHash.find(
                        (data) => data.key === rowData[0]
                      );
                      const name = foundData ? foundData.name : "";
                      return (
                        <div dangerouslySetInnerHTML={{ __html: name }}></div>
                      );
                    }}
                    header="Name"
                    sortable
                    style={{ minWidth: "10rem" }}
                  ></Column>

                  <Column
                    field="0"
                    header="Description"
                    sortable
                    style={{ minWidth: "16rem", display: "none" }}
                  ></Column>
                  <Column
                    body={(rowData) => renderValueColumn(rowData)}
                    field="1"
                    header="Value"
                    //editor={(options) => textEditor(options)}
                    editor={(props) => { debugger;
                      const rowData = props.rowData;
                      const detailDataKeysHash = JSON.parse(
                        sessionStorage.getItem("detail_data")
                      );
                      const foundData = detailDataKeysHash.find(
                        (data) => data.key === rowData[0]
                      );
                      const filter =
                        foundData.filter == true && foundData.filter_body.type=="category"
                          ? foundData.filter_body.options
                          : "";
                          if(rowData[0].includes('date')){
                            return(
                              <input
                              type="date"
                              onChange={(e) =>
                                props.editorCallback(e.target.value)
                              }
                              />                                     
                            )
                          }
                          else if (filter) {                        
                        return (
                          <select
                            //value={options.value}
                            onChange={(e) =>
                              props.editorCallback(e.target.value)
                            }
                          >
                            {filter.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>
                        );
                      } else {
                        return textEditor({  
                          value: rowData[1],  
                          editorCallback: (newValue) => props.editorCallback(newValue),  
                        });  
                      }
                    }}
                    sortable
                    style={{ minWidth: "18rem" }}
                  ></Column>
                  <Column
                    hidden={componentEditable ? false : true}
                    rowEditor
                    headerStyle={{ width: "10%", minWidth: "8rem" }}
                    bodyStyle={{ textAlign: "center" }}
                  ></Column>
                  <Column
                    //hidden={componentEditable ? false : true}
                    body={actionTemplate}                    
                    style={{ textAlign: "center", width: "4rem" }}
                    editor={(props) => (  
                      <CopyButtonEditor  
                        rowData={props.rowData}  
                        copyDataToClipboard={copyDataToClipboard}  
                      />
                    )}  
                  />
                </DataTable>
              </TabPanel>
            );
          })}
        </TabView>
        )}
        <button
          hidden={Btntype === "copy" ? false : true}
          onClick={() => setshowRevisionMsg(true)}
          className="primaryBtn  mx-auto mb-5"
          style={{ width: "50vh" }}
        >
          Replica
        </button>
        <button
          hidden={
            componentEditable === true && Btntype != "copy" ? false : true
          }
          className="primaryBtn  mx-auto mb-5"
          style={{ width: "50vh" }}
          onClick={(e) => updateComponent(e)}
        >
          Update
        </button>
        {/* </Dialog> */}
      </div>
    </>
  ) : null
};

export default ViewCompDetails;
