import React,{ useState } from 'react'
import PublicRoutes from './routes/Public';
import "./App.css"
import Header from './components/common/Header';
import Footer from './components/common/Footer';

const App = () => {
  const[showTool,setShowTool] = useState(false);
  return (
    <>
      <div id="canvas">
        <div id="box_wrapper">
          <div className="page_header_wrapper affix-wrapper" style={{ height: 60 }}>
            <Header displayTool={setShowTool}/>
          </div>
            <PublicRoutes showDetailedEvaluationTool={showTool}></PublicRoutes>
            <Footer />
        </div>
      </div>
    </>
  )
}

export default App