import React, { useState, useRef } from "react";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import { useEffect } from "react";
import axios from "axios";
import { Toast } from "primereact/toast";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";

const Modal = (props) => {
  debugger;
  const [detailsDataAray, setData] = useState([]);
  const access_token = localStorage.getItem("accessToken");
  const toast = useRef(null);
  const userRole = localStorage.getItem("userRole");
  const [ifReloaded, setIfReloaded] = useState(false);
  const loggedInUser = localStorage.getItem("loggedInUser");
  var DetailsData, DetailsDataArray2, componentEditable;
  var userRoleList = userRole.split(",");
  var adminUser = userRoleList.indexOf("ADMIN");
  var nonAdminUser = userRoleList.indexOf("NONEXPERT");
  var compVisibility; //props.compData.visible_to_all;

  const isComponentEditable = () => {
    if (props.Btntype === "copy") {
      componentEditable = true;
    } else if (adminUser >= 0) {
      componentEditable = true;
    } else if (nonAdminUser >= 0) {
      if (props.compData["created_by"] === loggedInUser) {
        componentEditable = true;
      }
    }
  };

  if (props.visibility) {debugger;
    DetailsData = props.compData.details;   
    DetailsDataArray2 = Object.entries(DetailsData);
    isComponentEditable();
    compVisibility = props.compData.visible_to_all;
  }

  const textEditor = (options) => {
    return (
      <InputText
        type="text"
        value={options.value}
        onChange={(e) => options.editorCallback(e.target.value)}
      />
    );
  };
  const cellEditor = (options) => {
    return textEditor(options);
  };

  const onRowEditComplete2 = (e) => {
    debugger;
    let { index, data, newData, field, originalEvent: event } = e;
    data[1] = newData[1];
    DetailsDataArray2[index][1] = newData[1];
    console.log(DetailsDataArray2);
  };
  const createJSONDetails = () => {};
  const updateComponent = () => {
    debugger;
    createJSONDetails();
    const token = access_token;

    axios
      .put(
        "https://esd-api.mosaic-dev.siemens-energy.cloud/api/v1/dev/comp/update-comp/" +
          props.compData.id,
        {
          comp: {
            name: props.compData.name,
            type: props.compData.type,
            desc: props.compData.desc,
            group: props.compData.group,
            source: props.compData.source,
            location_country_of_application:
              props.compData.location_country_of_application,
            quality_index: props.compData.quality_index,
            details: Object.fromEntries(DetailsDataArray2),
            technology_family: props.compData.technology_family,
            technology_system: props.compData.technology_system,
            family: props.compData.family,
            comment: props.compData.comment,
            data_collected_by: props.compData.data_collected_by,
            data_provided_by: props.compData.data_provided_by,
            data_imported_by: props.compData.data_imported_by,
            imported_data_checked_by: props.compData.imported_data_checked_by,
            price_data_type: props.compData.price_data_type,
            data_valid_until: props.compData.data_valid_until,
            project_name: props.compData.project_name,
            project_year: props.compData.project_year,
            data_year: props.compData.data_year,
            project_country: props.compData.project_country,
            applicable_to: props.compData.applicable_to,
            commodities_in: props.compData.commodities_in,
            commodities_out: props.compData.commodities_out,
            visible_to_all: false,
          },
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Component is updated",
          life: 3000,
        });
        console.log(response);
        setIfReloaded(true);
      });
  };

  const createDuplicateComp = () => {
    debugger;
    const token = access_token;

    axios
      .post(
        "https://esd-api.mosaic-dev.siemens-energy.cloud/api/v1/dev/comp/create-revision/" +
          props.compData.id +
          "?revision_msg=''",
        {
          comp: {
            name: props.compData.name,
            type: props.compData.type,
            desc: props.compData.desc,
            group: props.compData.group,
            source: props.compData.source,
            location_country_of_application:
              props.compData.location_country_of_application,
            quality_index: props.compData.quality_index,
            details: props.compData.details,
            technology_family: props.compData.technology_family,
            technology_system: props.compData.technology_system,
            family: props.compData.family,
            comment: props.compData.comment,
            data_collected_by: props.compData.data_collected_by,
            data_provided_by: props.compData.data_provided_by,
            data_imported_by: props.compData.data_imported_by,
            imported_data_checked_by: props.compData.imported_data_checked_by,
            price_data_type: props.compData.price_data_type,
            data_valid_until: props.compData.data_valid_until,
            project_name: props.compData.project_name,
            project_year: props.compData.project_year,
            data_year: props.compData.data_year,
            project_country: props.compData.project_country,
            applicable_to: props.compData.applicable_to,
            commodities_in: props.compData.commodities_in,
            commodities_out: props.compData.commodities_out,
            visible_to_all: false,
          },
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Duplicate Component Created",
          life: 3000,
        });
        console.log(response);
        setIfReloaded(true);
      });
  };

  const onClick = () => {
    debugger;
    const updatedCompDetails = Object.fromEntries(DetailsDataArray2);
    debugger;
    props.compData.details = updatedCompDetails;
    createDuplicateComp();
  };

  const accept = () => {
    debugger;
    const token = access_token;

    axios
      .put(
        "https://esd-api.mosaic-dev.siemens-energy.cloud/api/v1/dev/comp/update-comp/" +
          props.compData.id,
        {
          comp: {
            name: props.compData.name,
            type: props.compData.type,
            desc: props.compData.desc,
            group: props.compData.group,
            source: props.compData.source,
            location_country_of_application:
              props.compData.location_country_of_application,
            quality_index: props.compData.quality_index,
            details: Object.fromEntries(DetailsDataArray2),
            technology_family: props.compData.technology_family,
            technology_system: props.compData.technology_system,
            family: props.compData.family,
            comment: props.compData.comment,
            data_collected_by: props.compData.data_collected_by,
            data_provided_by: props.compData.data_provided_by,
            data_imported_by: props.compData.data_imported_by,
            imported_data_checked_by: props.compData.imported_data_checked_by,
            price_data_type: props.compData.price_data_type,
            data_valid_until: props.compData.data_valid_until,
            project_name: props.compData.project_name,
            project_year: props.compData.project_year,
            data_year: props.compData.data_year,
            project_country: props.compData.project_country,
            applicable_to: props.compData.applicable_to,
            commodities_in: props.compData.commodities_in,
            commodities_out: props.compData.commodities_out,
            visible_to_all: compVisibility === true ? false : true,
          },
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        toast.current.show({
          severity: "info",
          summary: "Confirmed",
          detail: "Visibility has been changed",
          life: 3000,
        });
      })
      .then(() => {
        window.location.reload();
      });
  };

  const reject = () => {
    toast.current.show({
      severity: "warn",
      summary: "Rejected",
      detail: "You have rejected",
      life: 3000,
    });
  };

  const UpdateVisibility = () => {
    debugger;
    confirmDialog({
      message: `Currently this Component is ${
        compVisibility === true ? "visible to all." : "not visible to all."
      }
         Are You sure you want to change its visibility?`,
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept,
      reject,
    });
  };

  return props.visibility ? (
    <>
      <Toast ref={toast} />
      <Dialog
        header={
          <div class="row">
            <div class="column">
              <label className="p-dialog-title">Component Details</label>
            </div>
            <div class="column">
              <ConfirmDialog />
              <Button
                className="btn-siemens"
                onClick={UpdateVisibility}
                hidden={adminUser >= 0 ? false : true}
              >
                Change Visibility
              </Button>
            </div>
          </div>
        }
        visible={props.visibility}
        style={{ width: "80vw" }}
        footer={props.renderFooter("displayBasic")}
        onHide={() => props.onHide("displayBasic", { ifReloaded })}
      >
        <div className="datatable-editing-demo">
          <div className="p-fluid">
            <DataTable
              value={DetailsDataArray2}
              editMode="row"
              responsiveLayout="scroll"
              onRowEditComplete={onRowEditComplete2}
            >
              <Column
                field="0"
                header="Description"
                sortable
                style={{ minWidth: "16rem" }}
              ></Column>
              <Column
                field="1"
                header="Value"
                editor={(options) => textEditor(options)}
                sortable
                style={{ minWidth: "16rem" }}
              ></Column>
              <Column
                hidden={componentEditable ? false : true}
                rowEditor
                headerStyle={{ width: "10%", minWidth: "8rem" }}
                bodyStyle={{ textAlign: "center" }}
              ></Column>
            </DataTable>
          </div>
        </div>
        <button
          hidden={props.Btntype === "copy" ? false : true}
          onClick={() => onClick()}
          className="btn-siemens"
        >
          Replica
        </button>
        <button
          hidden={
            componentEditable === true && props.Btntype != "copy" ? false : true
          }
          className="btn-siemens"
          onClick={() => updateComponent()}
        >
          Update
        </button>
      </Dialog>
    </>
  ) : null;
};

export default Modal;
