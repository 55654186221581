import React from 'react'

const ProjectCategories = ({component}) => {
    return (
        <>
         {component.comps.map((res, index) => {
                return(<div className="accordion-content mt-2 filter__title" key={index}>
                   {res.name}
                </div>)
         })}
        </>
    )
}

export default ProjectCategories