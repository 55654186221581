import React from 'react'

const Footer = () => {
    return (
        <>
            {/* <footer className="page_footer theme_footer ds ms parallax section_padding_top_90 section_padding_bottom_50">
                <div className="container">
                    <div className="row">
                        <div
                            className="col-md-3 col-sm-6"
                            data-animation="scaleAppear"
                        >
                            <div
                                className="widget widget_text topmargin_20"
                                style={{ height: 280 }}
                            >
                                <p className="bottommargin_30">
                                    <img src="/images/SE_Logo_White_RGB.svg" alt="" />
                                </p>
                                <p className="bottommargin_25">
                                    Sed cursus tortor neque, sit amet dignissim purus pretium vel.
                                    Nullam et sodales sem. Fusce elit urna, accumsan id mollis eu,
                                    egestas a nunc.
                                </p>
                                <div className="page_social_icons inline-block darklinks">
                                    <a
                                        className="social-icon soc-facebook"
                                        href="https://html.modernwebtemplates.com/energy/index.html#"
                                        title="Facebook"
                                    />
                                    <a
                                        className="social-icon soc-twitter"
                                        href="https://html.modernwebtemplates.com/energy/index.html#"
                                        title="Twitter"
                                    />
                                    <a
                                        className="social-icon soc-google"
                                        href="https://html.modernwebtemplates.com/energy/index.html#"
                                        title="Google"
                                    />
                                    <a
                                        className="social-icon soc-linkedin"
                                        href="https://html.modernwebtemplates.com/energy/index.html#"
                                        title="LinkedIn"
                                    />
                                </div>
                            </div>
                        </div>
                        <div
                            className="col-md-3 col-sm-6 "
                            data-animation="scaleAppear"
                        >
                            <div
                                className="widget widget_text topmargin_25"
                                style={{ height: 280 }}
                            >
                                <h4 className="text-uppercase bottommargin_20">contact us</h4>
                                <div className="media">
                                    <div className="media-left">
                                        <i className="fa fa-map-marker highlight fontsize_18" />
                                    </div>
                                    <div className="media-body">
                                        1116 15th St, Sacramento, USA
                                    </div>
                                </div>
                                <div className="media">
                                    <div className="media-left">
                                        <i className="fa fa-phone highlight fontsize_18" />
                                    </div>
                                    <div className="media-body">
                                        0800 123 4567
                                        <br /> 0800 123 4566
                                    </div>
                                </div>
                                <div className="media">
                                    <div className="media-left">
                                        <i className="fa fa-envelope highlight fontsize_18" />
                                    </div>
                                    <div className="media-body greylinks">
                                        <a href="https://html.modernwebtemplates.com/energy/index.html#">
                                            energy@support.com
                                        </a>
                                        <br />
                                        <a href="https://html.modernwebtemplates.com/energy/index.html#">
                                            energy@info.com
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="col-md-3 col-sm-6 "
                            data-animation="scaleAppear"
                        >
                            <div
                                className="widget widget_twitter topmargin_25"
                                style={{ height: 280 }}
                            >
                                <h4 className="text-uppercase bottommargin_20">
                                    Twitter widget
                                </h4>
                                <div className="twitter">
                                    <ul className="tweet_list" />
                                </div>
                            </div>
                        </div>
                        <div
                            className="col-md-3 col-sm-6 "
                            data-animation="scaleAppear"
                        >
                            <div
                                className="widget widget_slider topmargin_25"
                                style={{ height: 280 }}
                            >
                                <h4 className="widget-title text-uppercase">Gallery</h4>
                                <div
                                    className="owl-carousel topmargin_30 owl-loaded owl-drag owl-theme"
                                    data-nav="false"
                                    data-dots="true"
                                    data-loop="true"
                                    data-autoplay="true"
                                    data-items={1}
                                    data-responsive-lg={1}
                                    data-responsive-md={1}
                                    data-responsive-sm={1}
                                    data-responsive-xs={1}
                                >
                                    <div className="owl-stage-outer">
                                        <div
                                            className="owl-stage"
                                            style={{
                                                transform: "translate3d(-879px, 0px, 0px)",
                                                transition: "all 0.25s ease 0s",
                                                width: 2051
                                            }}
                                        >
                                            <div
                                                className="owl-item cloned"
                                                style={{ width: 263, marginRight: 30 }}
                                            >
                                                <div className="vertical-item">
                                                    <div className="item-media">
                                                        <img src="./08.jpg" alt="" />
                                                        <div className="media-links middle-links type2">
                                                            <div className="links-wrap">
                                                                <a
                                                                    className="p-view prettyPhoto"
                                                                    title=""
                                                                    data-gal="prettyPhoto[gal]"
                                                                    href="./08.jpg"
                                                                />
                                                                <a
                                                                    href="https://html.modernwebtemplates.com/energy/index.html#"
                                                                    className="p-like"
                                                                />
                                                                <a
                                                                    className="p-link"
                                                                    href="https://html.modernwebtemplates.com/energy/gallery-single.html"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="owl-item cloned"
                                                style={{ width: 263, marginRight: 30 }}
                                            >
                                                <div className="vertical-item">
                                                    <div className="item-media">
                                                        <img src="./09.jpg" alt="" />
                                                        <div className="media-links middle-links type2">
                                                            <div className="links-wrap">
                                                                <a
                                                                    className="p-view prettyPhoto"
                                                                    title=""
                                                                    data-gal="prettyPhoto[gal]"
                                                                    href="./09.jpg"
                                                                />
                                                                <a
                                                                    href="https://html.modernwebtemplates.com/energy/index.html#"
                                                                    className="p-like"
                                                                />
                                                                <a
                                                                    className="p-link"
                                                                    href="https://html.modernwebtemplates.com/energy/gallery-single.html"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="owl-item"
                                                style={{ width: 263, marginRight: 30 }}
                                            >
                                                <div className="vertical-item">
                                                    <div className="item-media">
                                                        <img src="./07.jpg" alt="" />
                                                        <div className="media-links middle-links type2">
                                                            <div className="links-wrap">
                                                                <a
                                                                    className="p-view prettyPhoto"
                                                                    title=""
                                                                    data-gal="prettyPhoto[gal]"
                                                                    href="./07.jpg"
                                                                />
                                                                <a
                                                                    href="https://html.modernwebtemplates.com/energy/index.html#"
                                                                    className="p-like"
                                                                />
                                                                <a
                                                                    className="p-link"
                                                                    href="https://html.modernwebtemplates.com/energy/gallery-single.html"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="owl-item active"
                                                style={{ width: 263, marginRight: 30 }}
                                            >
                                                <div className="vertical-item">
                                                    <div className="item-media">
                                                        <img src="./08.jpg" alt="" />
                                                        <div className="media-links middle-links type2">
                                                            <div className="links-wrap">
                                                                <a
                                                                    className="p-view prettyPhoto"
                                                                    title=""
                                                                    data-gal="prettyPhoto[gal]"
                                                                    href="./08.jpg"
                                                                />
                                                                <a
                                                                    href="https://html.modernwebtemplates.com/energy/index.html#"
                                                                    className="p-like"
                                                                />
                                                                <a
                                                                    className="p-link"
                                                                    href="https://html.modernwebtemplates.com/energy/gallery-single.html"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="owl-item"
                                                style={{ width: 263, marginRight: 30 }}
                                            >
                                                <div className="vertical-item">
                                                    <div className="item-media">
                                                        <img src="./09.jpg" alt="" />
                                                        <div className="media-links middle-links type2">
                                                            <div className="links-wrap">
                                                                <a
                                                                    className="p-view prettyPhoto"
                                                                    title=""
                                                                    data-gal="prettyPhoto[gal]"
                                                                    href="./09.jpg"
                                                                />
                                                                <a
                                                                    href="https://html.modernwebtemplates.com/energy/index.html#"
                                                                    className="p-like"
                                                                />
                                                                <a
                                                                    className="p-link"
                                                                    href="https://html.modernwebtemplates.com/energy/gallery-single.html"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="owl-item cloned"
                                                style={{ width: 263, marginRight: 30 }}
                                            >
                                                <div className="vertical-item">
                                                    <div className="item-media">
                                                        <img src="./07.jpg" alt="" />
                                                        <div className="media-links middle-links type2">
                                                            <div className="links-wrap">
                                                                <a
                                                                    className="p-view prettyPhoto"
                                                                    title=""
                                                                    data-gal="prettyPhoto[gal]"
                                                                    href="./07.jpg"
                                                                />
                                                                <a
                                                                    href="https://html.modernwebtemplates.com/energy/index.html#"
                                                                    className="p-like"
                                                                />
                                                                <a
                                                                    className="p-link"
                                                                    href="https://html.modernwebtemplates.com/energy/gallery-single.html"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="owl-item cloned"
                                                style={{ width: 263, marginRight: 30 }}
                                            >
                                                <div className="vertical-item">
                                                    <div className="item-media">
                                                        <img src="./08.jpg" alt="" />
                                                        <div className="media-links middle-links type2">
                                                            <div className="links-wrap">
                                                                <a
                                                                    className="p-view prettyPhoto"
                                                                    title=""
                                                                    data-gal="prettyPhoto[gal]"
                                                                    href="./08.jpg"
                                                                />
                                                                <a
                                                                    href="https://html.modernwebtemplates.com/energy/index.html#"
                                                                    className="p-like"
                                                                />
                                                                <a
                                                                    className="p-link"
                                                                    href="https://html.modernwebtemplates.com/energy/gallery-single.html"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="owl-nav disabled">
                                        <div className="owl-prev">prev</div>
                                        <div className="owl-next">next</div>
                                    </div>
                                    <div className="owl-dots">
                                        <div className="owl-dot">
                                            <span />
                                        </div>
                                        <div className="owl-dot active">
                                            <span />
                                        </div>
                                        <div className="owl-dot">
                                            <span />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <section className="page_copyright ds ms parallax table_section section_padding_25">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 text-center">
                            <p className="darklinks fontsize_12">
                                ©<span className="text-uppercase">Siemens Energy&nbsp;</span>2022
                            </p>
                        </div>
                    </div>
                </div>
            </section> */}
        </>
    )
}

export default Footer