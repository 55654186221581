import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux';
import { delItem } from '../redux/actions/index'
import { useSelector } from 'react-redux'
import axios from "axios";
import { clearCart } from '../redux/actions/index'

const Cart = () => {debugger;
    // eslint-disable-next-line
    const [project, setProject] = useState(JSON.parse(localStorage.getItem('project')))
    const access_token = localStorage.getItem('accessToken'); 
    // const [cartComp,setCartComp] = useState(JSON.parse(localStorage.getItem('cart')))
    const dispatch = useDispatch()
    const cartComp = useSelector((state) => state.addItem)
    const navigate = useNavigate();
    const removeFromCart = (comp) => {
        dispatch(delItem(comp))
        // setCartComp(JSON.parse(localStorage.getItem('cart')))
    }

    const saveProject = () => {debugger;
        const token = access_token;
        let comp_Array = [];
        cartComp.map((res) => (
            comp_Array.push(res.id)
        ))
        let new_Array = [];

        project.comps.map((res)=> (
            new_Array.push(res.id)
        ))

        let final_Array = [];
        final_Array = comp_Array.concat(new_Array);
        console.log(final_Array,'finalArray');
        axios.put(
            'https://esd-api.mosaic-dev.siemens-energy.cloud/api/v1/dev/project/update-project/' + project.id,
            {
                "project": {
                    'name': project.name,
                    'comps': final_Array,
                    'notes': []
                }
            },
            { headers: { Authorization: `Bearer ${token}` } },



        ).then(response => {
            dispatch(clearCart());
            navigate('/checkout/' + project.id);
        });
    }

    const goBack = () => {
        navigate('/dashboard/' + project.id);
    }

    return (
        <>
            <div className="container">
                <div className="block-header__body">
                    <div
                        className="breadcrumb"
                        aria-label="breadcrumb"
                    >
                    </div>
                    <h1 className="block-header__title" style={{color:"#8A00E5"}}
                    >Shopping Cart 
                    {/* ({project.name}) */}
                    </h1>
                </div>
            </div>
            <div className="form-group mb-0 mt-0">
                    <button type="button" className="btn btn-sm pt-0" onClick={() => goBack()}>
                    <i class="fa fa-arrow-left" aria-hidden="true"></i> <span className='text-capitalize font-weight-bold'>Back to Project Components</span>
                    </button>
                </div>
            <div className="cart">
                <div className="cart__table cart-table">
                    <table className="cart-table__table table-striped ">
                        <thead className="cart-table__head">
                            <tr className="cart-table__row" style={{backgroundColor:"#8A00E5"}}>
                                <th className="cart-table__column cart-table__column--image">
                                    Image
                                </th>
                                <th className="cart-table__column cart-table__column--price">
                                    Component Name
                                </th>
                                <th className="cart-table__column cart-table__column--price">
                                    Type
                                </th>
                                <th className="cart-table__column cart-table__column--quantity">
                                    Revision ID
                                </th>
                                <th className="cart-table__column cart-table__column--total">
                                    Created By
                                </th>
                                <th className="cart-table__column cart-table__column--remove">
                                    <span className="sr-only">Remove</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody className="cart-table__body">
                            {cartComp.length ? (<>
                                {
                                    cartComp.map((res, index) => {
                                        return (<tr className="cart-table__row" key={index} >
                                            <td className="cart-table__column cart-table__column--image">
                                                <div className="image image--type--product">
                                                    <a
                                                        className="image__body"
                                                        href="/themes/red/products/brandix-spark-plug-kit-asr-400"
                                                    >
                                                        <img
                                                            alt=""
                                                            className="image__tag ml-5" style={{height:"10vh"}}
                                                            src="/images/no-image.jpg"
                                                        />
                                                    </a>
                                                </div>
                                            </td>
                                            <td className="cart-table__column cart-table__column--price">
                                                <a
                                                    className="cart-table__product-name"
                                                    href="/themes/red/products/brandix-spark-plug-kit-asr-400"
                                                >
                                                    {res.name}
                                                </a>
                                            </td>
                                            <td
                                                className="cart-table__column cart-table__column--price"
                                                data-title="Price"
                                            >
                                                {res.type}
                                            </td>
                                            <td
                                                className="cart-table__column cart-table__column--quantity"
                                                data-title="Quantity"
                                            >
                                                <div className="input-number cart-table__quantity">
                                                    {res.revision}
                                                </div>
                                            </td>
                                            <td
                                                className="cart-table__column cart-table__column--total"
                                                data-title="Total"
                                            >
                                                {res.created_by}
                                            </td>
                                            <td className="cart-table__column cart-table__column--remove">
                                                <button
                                                    type="button"
                                                    className="cart-table__remove btn btn-sm btn-icon btn-muted"
                                                    onClick={() => removeFromCart(res)}
                                                >
                                                    <svg xmlns="http://www.w3.org/2000/svg" width={12} height={12}>
                                                        <path d="M10.8 10.8c-.4.4-1 .4-1.4 0L6 7.4l-3.4 3.4c-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4L4.6 6 1.2 2.6c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0L6 4.6l3.4-3.4c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4L7.4 6l3.4 3.4c.4.4.4 1 0 1.4z" />
                                                    </svg>
                                                </button>
                                            </td>
                                        </tr>)
                                    })
                                }
                            </>) : (<>
                                <td> No Record Found</td>
                            </>)}


                        </tbody>
                        <tfoot className="cart-table__foot">
                            <tr>
                                <td colSpan={6}>
                                    <div className="cart-table__actions">
                                        <form className="cart-table__coupon-form form-row">
                                            <div className="form-group mb-0 col flex-grow-1">
                                                {/* <label htmlFor="coupon-code" className="sr-only">
                                                    Coupon Code
                                                </label> */}
                                                {/* <input
                                                    type="text"
                                                    className="form-control form-control-sm"
                                                    id="coupon-code"
                                                    placeholder="Coupon Code"
                                                /> */}
                                            </div>
                                            {/* <div className="form-group mb-0 col-auto">
                                                <button type="button" className="btn btn-sm btn-primary">
                                                    Go Back <i class="fa fa-arrow-left" aria-hidden="true"></i>
                                                </button>
                                            </div> */}
                                        </form>
                                        {cartComp.length ? (
                                            <div className="cart-table__update-button">
                                                <button
                                                    type="button"
                                                    className="primaryBtn"
                                                    disabled=""
                                                    onClick={() => saveProject()}
                                                >
                                                   <span className='text-capitalize'> Save Project</span>
                                                </button>


                                            </div>
                                        ) : ('')}

                                    </div>
                                </td>
                            </tr>
                        </tfoot>
                    </table>

                </div>
            </div>
        </>
    )
}

export default Cart