
import React, { useState, useEffect, useRef } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import axios from "axios";
import { saveAs } from "file-saver";
import { Toast } from "primereact/toast";


const ProjectDetailModel = (props) => {debugger;
  const toast = useRef(null);
  const access_token = localStorage.getItem('accessToken'); 

  const downloadProject = (projectId) => {debugger;
    const token = access_token;
    debugger;
    axios
      .get(
        "https://esd-api.mosaic-dev.siemens-energy.cloud/api/v1/dev/projectV2/download_project?id="+
          projectId,

        {
          headers: { Authorization: `Bearer ${token}` },
          responseType: "arraybuffer",
        }
      )
      .then((response) => {
        const blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(blob, "pToX.xlsx");
      });
  };

  const confirmDeleteProduct = (compId)=> {
    debugger;
    
    
    const updatedProjectComps = props.compData.comps.filter(comp => comp.id !== compId);
    const ProjectCompID=updatedProjectComps.map((comp)=>{return comp.id});
      let projectid=props.compData.id ;
      axios
        .put(
          "https://esd-api.mosaic-dev.siemens-energy.cloud/api/v1/dev/project/update-project/" +
            projectid,
          {
            project: {
              name: props.compData.name,
              comps: ProjectCompID,
              notes: [],
            },
          },
          { headers: { Authorization: `Bearer ${access_token}` } }
        )
        .then((response) => {          
          toast.current.show({
            severity: "success",
            summary: "Successful",
            detail: "Component deleted from this Project",
            life: 3000,
          })
        })
          .then(() => {
            window.location.reload();
          });
         
        
      
    }
   


  return props.visibility ? (
    <>
    <Toast ref={toast} />
      <Dialog
        header={props.compData.name}
        visible={props.visibility}
        style={{ width: "50vw" }}
        onHide={() => props.onHide("displayBasic")}
      >
        <hr />
        <div className="row ml-1">
          <h5>
            <b>Components Details</b>
          </h5>
        </div>
        <div className="mt-3 mb-3">
          {props.compData.comps.length ? (
            <div className="table-responsive">
              <table className="table table-hover">
                <thead className="thead-light">
                  <tr>                    
                    <th scope="col">Type</th>
                    <th scope="col">ID</th>                    
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {props.compData.comps.map((res, index) => {
                    return (
                      <>
                        <tr key={index}>                          
                          <td>{res.type}</td>
                          <td>{res.id}</td>                          
                          <td>
                          <Button
                          icon="pi pi-trash"
                          tooltip="Delete Project" 
                          tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 10}}
                          className="p-button-rounded p-button-warning mr-2"
                          style={{backgroundColor:"red"}}
                          onClick={() => confirmDeleteProduct(res.id)}          
                        />
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="row d-flex justify-content-center">
              <p>No Components Found</p>
            </div>
          )}
        </div>
        {/* <div className="row float-left">
          <Button label="Upload Project" icon="pi pi-upload" iconPos="right" />
        </div> */}
        <div className="row float-right">
          <Button
            label="Download Project"
            className="primaryBtn"
            icon="pi pi-download"
            iconPos="right"
            onClick={() => downloadProject(props.compData.id)}
          />
        </div>
      </Dialog>
    </>
  ) : null;
};

export default ProjectDetailModel;
