import React, { useState, useRef, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Switch from "react-switch";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import { Toast } from "primereact/toast";
import { useMsal } from "@azure/msal-react";
import { Tag } from "primereact/tag";

const Header = (props) => {
  const [activeLink, setActiveLink] = useState();
  const { instance, accounts } = useMsal();
  const [state, setState] = useState(false);
  const cartValue = useSelector((state) => state.addItem);
  const location = useLocation();
  localStorage.setItem("cart", JSON.stringify(cartValue));
  const loggedInUser =
    localStorage.getItem("loggedInUser") == null
      ? ""
      : localStorage.getItem("loggedInUser");
  const FirstName =
    localStorage.getItem("FirstName") == null
      ? ""
      : localStorage.getItem("FirstName");
  const LastName =
    localStorage.getItem("LastName") == null
      ? ""
      : localStorage.getItem("LastName");
  const Initial = FirstName != "" ? FirstName[0] + LastName[0] : "";
  const IsLoginPage = location.pathname === "/login" ? true : false;
  const [menuVisible, setMenuVisible] = useState(false);
  const navigate = useNavigate();
  const userRole =
    localStorage.getItem("userRole") == null
      ? ""
      : localStorage.getItem("userRole");
  var adminUser = userRole.indexOf("ADMIN");

  const menuRight = useRef(null);
  //const routerr = useRouter();
  const toast = useRef(null);

  useEffect(() => {
    debugger;
    const currentPath = window.location.pathname;
    const pathParts = currentPath.split("/");
    const isProjectPath =
      pathParts[pathParts.length - 1].toLowerCase() == "dashboard"
        ? "Dashboard"
        : pathParts[pathParts.length - 1] == "projects"
        ? "Projects"
        : [];
    console.log(isProjectPath);
    setActiveLink(isProjectPath);
  }, [window.location.pathname]);

  const adminLink = {
    label: "Admin App",
    icon: "pi pi-external-link",
    url: "https://esd-api.mosaic-dev.siemens-energy.cloud/api/v1/dev/app/detail-evaluation-admin-landing",
  };
  const items = [
    {
      // label::
      //   loggedInUser == undefined
      //     ? ""
      //     : loggedInUser,
      //     //loggedInUser.match(/^([^@]*)@/)[1].toUpperCase(),
      items:
        // {
        // label:"Role: "+userRole.toLowerCase(),
        // icon: "pi pi-fw pi-user"
        // },
        adminUser > 0
          ? [
              {
                label: "Admin App",
                icon: "pi pi-external-link",
                url: "https://esd-api.mosaic-dev.siemens-energy.cloud/api/v1/dev/app/detail-evaluation-admin-landing",
              },
              {
                label: "LogOut",
                icon: "pi pi-sign-out",
                command: () => {
                  handleLogout();
                },
              },
            ]
          : [
              {
                label: "LogOut",
                icon: "pi pi-sign-out",
                command: () => {
                  handleLogout();
                },
              },
            ],
    },
  ];

  const handleLogout = () => {
    if (accounts.length > 0) {
      instance.logoutPopup();
      // instance.logout({
      //   account: accounts[0],
      //   onRedirectNavigate: (url) => {

      //     const postLogoutRedirectUri = "/login";
      //     window.location.assign(postLogoutRedirectUri);
      //     return false;
      //   },

      // prompt: "none",
      // });
    }
    localStorage.clear();
    sessionStorage.clear();
    navigate("/login");
  };

  const handleChange = (checked) => {
    setState(checked);
    props.displayTool(checked);
  };

  const showName = () => {
    return loggedInUser;
  };
  return (
    <>
      <nav
        className="navbar navbar-expand-lg"
        style={{ height: "100%", backgroundColor: "#1b1532" }}
      >
        <div class="container-fluid">
          {/* <div className="nav-item" style={{ opacity: 0, height: "50%" }}>
              <Switch
                className="switchPosition"
                onChange={handleChange}
                checked={state}
              />
            </div> */}
          <a className="navbar-brand pr-auto" href="#">
            {IsLoginPage ? (
              ""
            ) : (
              <img
                alt="SE_Logo_White"
                src="/images/SE_Logo_White_RGB.svg"
                width="30%"
                height="20%"
              />
            )}
          </a>

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0 ml-5">
              <li
                className={`nav-item${
                  activeLink === "Projects" ? " active" : ""
                }`}
              >
                {location.pathname !== "/" && location.pathname !== "/login" ? (
                  <a
                    className="nav-link"
                    href="/projects"
                    style={{
                      color:
                        activeLink === "Projects"
                          ? "#ffffff"
                          : "rgba(255, 255, 255, 0.7)",
                      textDecoration:
                        activeLink === "Projects" ? "underline" : "none",
                      fontSize: "17px",
                    }}
                  >
                    Projects
                  </a>
                ) : (
                  ""
                )}
              </li>

              <li
                className={`nav-item${
                  activeLink === "Dashboard" ? " active" : ""
                }`}
              >
                {location.pathname !== "/" && location.pathname !== "/login" ? (
                  <a
                    className="nav-link"
                    href="/Dashboard"
                    style={{
                      color:
                        activeLink === "Dashboard"
                          ? "#ffffff"
                          : "rgba(255, 255, 255, 0.7)",
                      textDecoration:
                        activeLink === "Dashboard" ? "underline" : "none",
                      fontSize: "17px",
                    }}
                  >
                    DataBase
                  </a>
                ) : (
                  ""
                )}
              </li>
            </ul>

            <ul class="navbar-nav ml-auto">
              <li class="nav-item mr-auto">
                {location.pathname !== "/" &&
                location.pathname !== "/projects" &&
                location.pathname !== "/login" ? (
                  <div className="indicator indicator--trigger--click">
                    {/* <NavLink className="indicator__button" to="/cart">
                      <span className="indicator__icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={32}
                          height={32}
                        >
                          <circle cx="10.5" cy="27.5" r="2.5" />
                          <circle cx="23.5" cy="27.5" r="2.5" />
                          <path d="M26.4 21H11.2c-1.2 0-2.2-.8-2.4-1.9L5.4 4.8c-.1-.5-.5-.8-1-.8H1c-.6 0-1-.4-1-1s.4-1 1-1h3.4C5.8 2 7 3 7.3 4.3l3.4 14.3c.1.2.3.4.5.4h15.2c.2 0 .4-.1.5-.4l3.1-10c.1-.2 0-.4-.1-.4-.1-.1-.2-.2-.4-.2H14c-.6 0-1-.4-1-1s.4-1 1-1h15.5c.8 0 1.5.4 2 1s.6 1.5.4 2.2l-3.1 10c-.3 1.1-1.3 1.8-2.4 1.8z" />
                        </svg>
                        <span className="indicator__counter">
                          {cartValue.length ? cartValue.length : 0}
                        </span>
                      </span>
                    </NavLink> */}
                  </div>
                ) : (
                  ""
                )}
              </li>

              <li class="nav-item mt-auto mb-auto mr-auto">
                {IsLoginPage ? (
                  ""
                ) : (
                  <>
                    {adminUser > 0 && (
                      <Tag
                        className="mr-2 mb-2 pl-2 pr-2"
                        style={{ fontSize: "16px", backgroundColor: "#8a00e5" }}
                        value="ADMIN"
                        rounded
                      ></Tag>
                    )}
                    <Menu
                      model={items}
                      popup
                      ref={menuRight}
                      id="popup_menu_right"
                      popupAlignment="right"
                    />
                    <Button
                      icon={Initial == "" ? "pi pi-user" : ""}
                      className="p-button-rounded mr-auto"
                      style={{
                        fontWeight: "bold",
                        backgroundColor: "#8A00E5",
                        borderRadius: "50%",
                        backgroundColor: "white",
                        color: "black",
                      }}
                      onClick={(event) => menuRight.current.toggle(event)}
                    >
                      {Initial}
                    </Button>
                  </>
                )}
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
