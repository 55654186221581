import React, { Suspense } from 'react';
import Home from '../components/Home'
import { Routes, Route } from 'react-router-dom';
import Login from '../components/auth/login/Login';
import Dashboard from '../components/Dashboard';
import Cart from '../components/Cart';
import Project from '../components/projects/Project';
import Checkout from '../components/Checkout';
import Header from '../components/common/Header';
import PartA from '../components/CompForm/PartA';
import ViewCompDetails from '../components/ViewCompDetails';



const Public = (props) => {    
   return( <>
        <Suspense fallback={<div>Loading...</div>}>
            <Routes>
                
                <Route path="/" element={<Home {...props}/>} />
                <Route path="/login" element={<Login />} />                
                <Route path='/dashboard/:name' element={<Dashboard />} />
                <Route path='/dashboard' element={<Dashboard />} />
                <Route path='/cart' element={<Cart />} />
                <Route path='/projects' element = {<Project />} />
                <Route path='/projects/:id' element = {<Project />} />
                <Route path='/checkout/:id' element = {<Checkout /> } />
                <Route path='/ViewCompDetails/:id' element = {<ViewCompDetails/> } />
                
            </Routes>
            
        </Suspense>
    </>)
};

export default Public;