import React from 'react'
import "./Checkout.css";
import { useParams } from "react-router-dom";
import axios from "axios";
import { saveAs } from "file-saver";

const Checkout = () => {
    const params = useParams();

    const access_token = localStorage.getItem('accessToken'); 
   
    const downloadProject = (projectId) => {
        const token = access_token;

        axios.get(
            'https://esd-api.mosaic-dev.siemens-energy.cloud/api/v1/dev/project/project-download/' + params.id,
            { headers: { Authorization: `Bearer ${token}` },  responseType: "arraybuffer"},



        ).then(response => {
            const blob = new Blob([response.data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              });
              saveAs(blob, "pToX.xlsx");
        });

    }

    return (
        <div className="text-dec">
            <div className="card">
                <div
                    style={{
                        borderRadius: 200,
                        height: 200,
                        width: 200,
                        background: "#F8FAF5",
                        margin: "0 auto",
                        color:'green'
                    }}
                >
                    <i className="checkmark checkmark-ii">✓</i>
                </div>
                <h1 className="success-msg">Success</h1>
                <p className="project-text">
                    Project Saved Successfully
                    <br /> <br />
                     <button
                        type="button"
                        className="btn btn-sm btn-primary"
                        disabled=""
                        onClick={() => downloadProject()}
                    >
                          <i className="fa fa-download"></i> <span className='text-capitalize'>Download Project</span>
                    </button>

                </p>
            </div>
        </div>

    )
}

export default Checkout