import React, { useState, useEffect } from "react";
import "./Login.css";
import { useLocation, useNavigate } from "react-router-dom";
import {
  InteractionRequiredAuthError,
  InteractionStatus,
} from "@azure/msal-browser";
import { loginRequest } from "../../../authConfig";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import background from "../../../../src/logo.png";
import axios from "axios";

const Login = ({ props }) => {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const location = useLocation();
  const source = location.state?.source;
  const navigate = useNavigate();

  const handleLogin = async (instance) => {
    instance.loginPopup(loginRequest).catch((e) => {
      console.error(e);
    });
  };

  const GetBearerToken = async (accessToken) => {
    debugger;
    var headers = new Headers();
    var bearer = accessToken;
    headers.append("Authorization", bearer);

    var requestOptions = {
      method: "POST",
      headers: headers,
      redirect: "follow",
      crossDomain: true,
    };
    const res = await fetch(
      process.env.REACT_APP_GETACCESSTOKEN,
      requestOptions
    );
    const json = await res.json().catch((error) => console.log("error", error));
    if (json.access_token != null || json.access_token != "") {
      localStorage.setItem("accessToken", json.access_token);
      localStorage.setItem("userRole", json.role);
      localStorage.setItem("loggedInUser", json.email);
      localStorage.setItem("FirstName", json.given_name);
      localStorage.setItem("LastName", json.family_name);
      localStorage.setItem(
        "createCompRequiredAdminFlag",
        json.create_component_required_field_admin_flag
      );

      fetchDataAndSaveToSessionStorage(json.access_token);

      if(localStorage.getItem("RequestedLink")!=null){
        navigate(localStorage.getItem("RequestedLink"));  
        localStorage.removeItem("RequestedLink");
      }
      else{
        navigate("/dashboard");
      }
    }
    /*.then((response) => response.json())
      .then((responseJson) => { debugger;
        if (
          responseJson.access_token !== null ||
          responseJson.access_token !== ""
        ) {
          localStorage.setItem('accessToken',responseJson.access_token); 
          localStorage.setItem('userRole',responseJson.role);  
          localStorage.setItem('loggedInUser',responseJson.email);         
        }
      })
      .then((result) => console.log(result))
      .catch((error) => console.log("error", error));*/
  };

  // useEffect(() => {
  //   GetBearerToken();
  // });
  const fetchDataAndSaveToSessionStorage = (accessToken) => {
    debugger;
    const token = accessToken;
    axios
      .get(
        "https://esd-api.mosaic-dev.siemens-energy.cloud/api/v1/dev/detailV2/get-all-detailV2?" +
          "&order=" +
          false,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        const detail_data = response.data;
        // Saving detail data array
        const saved = !!detail_data
          ? sessionStorage.setItem("detail_data", JSON.stringify(detail_data))
          : false;
        if (!!detail_data) {
          const detail_data_types = setup_detail_data_types(detail_data);
          setup_detail_data_keys_hash(detail_data);
          setup_detail_data_column(detail_data);
          setup_detail_data_section_related_data(detail_data, detail_data_types);
        }
        return saved;
      })
      .catch((error) => console.error("Error fetching detail_data:", error));
  };

  // Saving detail data columns in each data(data[0]);
  function setup_detail_data_column(detail_data) {
    const detail_data_column = Object.keys(detail_data[0]).map((key) => key);
    sessionStorage.setItem(
      "detail_data_column",
      JSON.stringify(detail_data_column)
    );
  }

  // Saving detail data section related data into session
  function setup_detail_data_section_related_data(
    detail_data,
    detail_data_types
  ) {
    // Saving detail_data_section_hash
    const detail_data_section_ordered = {};
    const detail_data_section_hash = {};
    const detail_data_type_wise_details = {};
    for (const detail_data_type of detail_data_types) {
      // Getting details for individual type
      const detail_data_for_individual_type = detail_data.filter(
        (detail) => detail.type === detail_data_type
      );

      const detail_data_section_wise = detail_data_for_individual_type.reduce(
        (groups, detail) => {
          const groupKey = detail.section || "default"; // Set section to "default" if empty
          if (!groups[groupKey]) {
            groups[groupKey] = [];
          }
          groups[groupKey].push(detail);
          return groups;
        },
        {}
      );

      const sections_by_type = [
        ...new Set(
          detail_data_for_individual_type.map(
            (detail) => detail.section || "default"
          )
        ),
      ];

      const sections_by_type_sorted = customSortSection(sections_by_type);

      detail_data_section_ordered[detail_data_type] = sections_by_type_sorted;
      detail_data_section_hash[detail_data_type] = detail_data_section_wise;
      detail_data_type_wise_details[detail_data_type] =
        detail_data_for_individual_type;
    }
    sessionStorage.setItem(
      "detail_data_type_wise_details",
      JSON.stringify(detail_data_type_wise_details)
    );

    sessionStorage.setItem(
      "detail_data_section_hash",
      JSON.stringify(detail_data_section_hash)
    );

    sessionStorage.setItem(
      "detail_data_section_ordered",
      JSON.stringify(detail_data_section_ordered)
    );
  }
  // Saving detail data types
  function setup_detail_data_types(detail_data) {
    const detail_data_types = new Array(
      ...new Set(detail_data.map((item) => item.type))
    );
    sessionStorage.setItem(
      "detail_data_types",
      JSON.stringify(detail_data_types)
    );
    return detail_data_types;
  }

  // Saving detail data hash maps
  function setup_detail_data_keys_hash(detail_data) {
    const detail_data_keys_hash = detail_data.reduce(
      (result, { key, ...rest }) => {
        result[key] = rest;
        return result;
      },
      {}
    );
    sessionStorage.setItem(
      "detail_data_keys_hash",
      JSON.stringify(detail_data_keys_hash)
    );
    getFilteredData(detail_data);
  }

    // Custom Sort
    function customSortSection(arr) {
      const order = {
        "General Information": 1,
        "Price and Performance Data": 2,
        mmesd: 3,
        default: 4,
        Completion: 5,
      };
  
      return arr.sort((a, b) => {
        const aIndex = order[a] || Infinity;
        const bIndex = order[b] || Infinity;
  
        if (aIndex === Infinity) {
          if (["default", "Completion"].includes(b)) {
            return -1;
          } else if (
            [
              "General Information",
              "Price and Performance Data",
              "mmesd",
            ].includes(b)
          ) {
            return 1;
          } else {
            return a.localeCompare(b);
          }
        } else if (bIndex === Infinity) {
          if (["default", "Completion"].includes(a)) {
            return 1;
          } else if (
            [
              "General Information",
              "Price and Performance Data",
              "mmesd",
            ].includes(a)
          ) {
            return -1;
          } else {
            return a.localeCompare(b);
          }
        } else if (aIndex !== bIndex) {
          return aIndex - bIndex;
        }
      });
    }
    

  const getFilteredData = (detail_data) => {
    debugger;
    var filteredData = {};

    for (var i = 0; i < detail_data.length; i++) {
      debugger;
      var item = detail_data[i];
      var compType = item.category;

      if (item.filter === true) {
        var compType = item.category;
        var filterBody = item.filter_body;

        if (!(compType in filteredData)) {
          filteredData[compType] = [];
        }

        filteredData[compType].push(filterBody);
        filterBody.key = item.key;
        filterBody.section = item.section;

        sessionStorage.setItem(
          "detail_data_keys_filter",
          JSON.stringify(filteredData)
        );
      }
    }
  };

  const ProtectedComponent = () => {
    const { instance, inProgress, accounts } = useMsal();
    // eslint-disable-next-line
    const [apiData, setApiData] = useState(null);

    useEffect(() => {
      if (!apiData && inProgress === InteractionStatus.None) {
        const accessTokenRequest = {
          scopes: ["user.read"],
          account: accounts[0],
        };
        instance
          .acquireTokenSilent(accessTokenRequest)
          .then((accessTokenResponse) => {
            // Acquire token silent success
            let accessToken = accessTokenResponse.accessToken;
            localStorage.setItem("AccessToken", accessToken);
            console.log("Azure Token", accessToken);
            GetBearerToken(accessToken);
            //changes done by neha
            //navigate("/projects");
            // Call your API with token
            // callApi(accessToken).then((response) => {
            //   setApiData(response);
            // });
          })
          .catch((error) => {
            if (error instanceof InteractionRequiredAuthError) {
              instance
                .acquireTokenPopup(accessTokenRequest)
                .then(function (accessTokenResponse) {
                  // Acquire token interactive success
                  console.log(accessTokenResponse.accessToken);
                  // Call your API with token
                  // callApi(accessToken).then((response) => {
                  //   console.log(response);
                  //   setApiData(response);
                  // });
                })
                .catch(function (error) {
                  // Acquire token interactive failure
                  console.log(error);
                });
            }
            console.log(error);
          });
      }
    }, [instance, accounts, inProgress, apiData]);

    return <p>Return your protected content here: {apiData}</p>;
  };

  return (
    <>
      <div
        className="form-container"
        style={{ backgroundImage: `url(${background})`, margin: 0, padding: 0 }}
      >
        <div className="formBackground">
          <div
            className="logo ml-5"
            style={{ paddingLeft: "7%", width: "45%" }}
          >
            {/* <IconSiemensEnergy /> */}
            <img
              alt="login"
              src="./images/SE_Logo_White_RGB.svg"
              style={{ width: "100%" }}
            ></img>
          </div>

          <div className="form">
            <br />
            <div></div>
            <div style={{ marginBottom: "10%" }}>
              <h5 className="ml-2">
                Siemens Energy Asset Database for energy system design studies
              </h5>
              {isAuthenticated ? (
                <ProtectedComponent />
              ) : (
                // <span>
                // {window.location.replace('https://ptx-tool.mosaic-dev.siemens-energy.cloud/projects')}</span>
                <button
                  type="button"
                  style={{ width: "30vh", height: "7vh" }}
                  className="primaryBtn ml-2 mr-5 b mt-4 font-weight-bold"
                  onClick={() => handleLogin(instance)}
                >
                  Azure AD
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;

// Login.propTypes = {
//     setToken: PropTypes.func.isRequiredd
//   };
