import React, { useEffect, useState, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { Password } from "primereact/password";
import { Checkbox } from "primereact/checkbox";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Divider } from "primereact/divider";
import { classNames } from "primereact/utils";
import "primereact/resources/themes/lara-light-indigo/theme.css"; // theme
import "primereact/resources/primereact.css"; // core css
import "primeicons/primeicons.css";
import "./FormDemo.css";
import axios from "axios";
import { Toast } from "primereact/toast";
import { TabView, TabPanel } from "primereact/tabview";

const PartA = (props) => {
  debugger;
  var showDialogue = `${props.visibility}`;
  const access_token = localStorage.getItem("accessToken");
  const [showMessage, setShowMessage] = useState(false);
  const [isVisible, setisVisible] = useState(showDialogue);
  const [formData, setFormData] = useState([]);
  const [orderDropdown, setorderDropdown] = useState([]);
  const [compDetailData, setcomponentDetail] = useState([]);
  const [displayDialogue, setDisplayDialogue] = useState(false);
  const [CompAttribute, setCompAttribute] = useState([]);
  const [selectedCompType, setSelectedCompType] = useState([]);
  const [componentVisible2All, setcomponentVisible2All] = useState(false);
  var ifCompSelected;
  const toast = useRef(null);
  const isAdminUser = localStorage.getItem("AdminUser") ? true : false;

  const compTypeList = ["Li-Ion Battery", "Heat Pump", "Photovoltaics"];
  const [location_country_of_application, setLocation] = useState("");
  const [compType, setComp] = useState("");
  const [compDetail, setCompDetail] = useState([]);
  var compList = [""];
  const [editedData, setEditedData] = useState({});
  const [tabData, setTabData] = useState({});
  const [fields, setFields] = useState([]);
  const [stateList, setStateList] = useState([{}]);
  const [tab, setTab] = useState([]);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [values, setValues] = useState({});
  const DefaultValues = [];
  const [Summary, setSummary] = useState({});
  const [isSummaryReady, setIsSummaryReady] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [isVisibleToALL, setIsVisibleToALL] = useState(false);
  const [sectionOrder, setSectionOrder] = useState([]);
  const inputRefs = useRef([]);
  const [tabledata, setData] = useState([{}]);
  const [expandedRows, setExpandedRows] = useState(null);
  const [selectedDdnVAlue, setSelectedDropDownValue] = useState();
  const [missingFieldKey, setMissingFieldKey] = useState(null);
  const [missingFielSection, setMissingFieldSection] = useState(null);
  const missingFieldRef = useRef(null);
  const adminRequired = false//localStorage.getItem(
  //   "createCompRequiredAdminFlag"
  // );

  let headers = compDetail.map((Section) => Section || "default");
  headers.push("Summary");
  const {
    control,
    handleSubmit,
    trigger,
    register,
    formState,
    //formState: { errors },
    reset,
    getValues,
    setValue,
    fieldState,
    setError,
  } = useForm({
    mode: "onBlur",
  });

  useEffect(() => {  
    compDetailData.forEach((item) => {  
      setValue(  
        `tabs[${  
          item.section == null  
            ? 'default'  
            : item.section == ''  
            ? 'default'  
            : item.section  
        }].${item.key}`,  
        item.value==null?'':item.value  
      );  
    });  
  }, [compDetailData]);  

  const { errors } = formState;

  const handleCheckboxClick = () => {
    setIsVisibleToALL(!isVisibleToALL);
  };

  const getComponentType = () => {
    const token = access_token;
    axios
      .get(
        "https://esd-api.mosaic-dev.siemens-energy.cloud/api/v1/dev/comp/comp-detail-type/",

        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((res) => {
        setorderDropdown(res.data);
      });
  };
  const createCompSection = async (compList) => {
    const List1 = compList;
    await setCompDetail({ List1 });
  };

  const getComponentDetailList = (Comptype) => {
    const token = access_token;

    axios
      .get(
        "https://esd-api.mosaic-dev.siemens-energy.cloud/api/v1/dev/detailV2/comp-detail-type/" +
          Comptype +
          "?verbose=true",

        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((res) => {
        //setcomponentDetail(Object.keys(res.data.details));
        //setcomponentDetail(res.data);

        //compList = Array.from(new Set(res.data.map((item) => item.section)));
        //setCompDetail(compList);
        //setcomponentDetail(res.data);
        setTab(res.data);
        const sectionOrder = JSON.parse(
          sessionStorage.getItem("detail_data_section_ordered")
        )[Comptype];
        setCompDetail(sectionOrder);
        const dataArray = Object.values(res.data);

        // Sort the data array using the custom sorting function
        dataArray.sort((a, b) => {
          const sectionA = sectionOrder.indexOf(a.section);
          const sectionB = sectionOrder.indexOf(b.section);
          return sectionA - sectionB;
        });

        // Print the sorted data
        console.log(dataArray);
        setcomponentDetail(dataArray);
        //{res.data.map((item)=>{
        // DefaultValues.push({})
        // })}

        {
          res.data.map((item, index) => {
            const dummyObj = {
              key: item.key,
              Value: "",
              Section: item.section,
            };
            stateList.push(dummyObj);
          });
        }
      });
  };
  useEffect(() => {}, [activeTabIndex]);

  useEffect(() => {
    console.log(Summary);
  }, [Summary]);

  const setSelectedOption = (e, field) => {
    ///field.value=e.value;
    if (e != "Choose...") {
      ifCompSelected = true;
      setSelectedCompType(e);
      reset(); // Reset the form
      setFormData([]);
      setcomponentDetail([]);
      getComponentDetailList(e);
    } else {
      ifCompSelected = false;
    }
  };

  useEffect(() => {
    getComponentType();
    //setorderDropdown(compTypeList);
    reset();
    setCompAttribute("");
    //getComponentDetailList();
  }, []);

  useEffect(
    () => {
      if (missingFieldKey) {
        console.log("useEffect entered with missingFieldKey:", missingFieldKey);
        const missingFieldElement = document.getElementById(
          `tabs[${missingFielSection}].${missingFieldKey}`
        );
        console.log(
          "useEffect called with missingFieldKey:",
          missingFieldElement
        );
        if (missingFieldElement) {
          missingFieldElement.focus();
        }
      }
    },
    [missingFieldKey],
    [missingFielSection]
  );

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  const dialogFooter = (
    <div className="flex justify-content-center">
      <Button
        label="OK"
        className="p-button-text"
        autoFocus
        onClick={() => {
          setShowMessage(false);
          props.onHide("displayCompDialogue", false);
        }}
      />
    </div>
  );

  const onVisibilityChange = (e) => {
    if (e.checked) {
      setcomponentVisible2All(true);
    } else {
      setcomponentVisible2All(false);
    }
  };
  const sendCompDetailForm = () => {
    debugger;

    if (
      document.getElementById("view-option").value ===
      "Select Type of Component"
    ) {
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: "Choose Type of Component",
        life: 3000,
      });
    } else {
      setDisplayDialogue(true);
    }
  };
  const onHide = () => {
    setDisplayDialogue(false);
  };

  const getFormData = (data) => {
    debugger;
    if (CompAttribute != "") {
      setCompAttribute("");
    }
    setCompAttribute(data);
  };

  const getHeader = () => {
    return (
      <div style={{ color: "#212529", fontSize: "15px" }}>Create Component</div>
    );
  };

  const handleTabChange = (event) => {
    debugger;

    setActiveTabIndex(event.index);
  };

  const handleFormSubmit = async (data) => {
    debugger;

    // Handle saving data for each tab
    if (activeTabIndex != headers.length - 1) {
      debugger;
      setActiveTabIndex(activeTabIndex + 1);

      //handleTabChange(activeTabIndex+1);

      const ss = data.tabs;

      //fetch table data for operation points
      const dynamicAttributes = Object.keys(tabledata[0]);
      const result = [];

      for (const row of tabledata) {
        const newRow = [];

        for (let i = 0; i < dynamicAttributes.length; i++) {
          const attribute = dynamicAttributes[i];

          const value = parseInt(row[attribute]);

          newRow.push(value);
        }

        result.push(newRow);
      }
      for (const key in ss["Performance Indication"]) {
        if (key === "table_data") {
          ss["Performance Indication"]["hi_operation_point"] = result;
          delete ss["Performance Indication"]["table_data"];
        }
      }
      //compare summary with mendatory field
      const detailDataKeysHash = JSON.parse(
        sessionStorage.getItem("detail_data_type_wise_details")
      )[selectedCompType];

      const allKeys = [];
      Object.keys(data.tabs).forEach((tab) => {
        Object.entries(data.tabs[tab]).forEach(([key, value]) => {
          allKeys.push({ key, value });
        });
      });

      // Find the missing keys from keyList in data.tabs

      const missingOrEmptyKeys = detailDataKeysHash.filter((item) => {  
        return item.mandatory === "REQ" && ((isAdminUser && adminRequired) || !isAdminUser)  
          ? (() => {  
              const foundItem = allKeys.find((element) => element.key === item.key);  
              return !foundItem || foundItem.value === "";  
            })()  
          : (() => {  
              return false;  
            })();  
      });  
    

      console.log("Missing or empty keys (required):", missingOrEmptyKeys);
      let isSummaryValid = <tr></tr>;

      if (missingOrEmptyKeys.length > 0) {
        debugger;
        const missingSection = missingOrEmptyKeys[0].section;
        const missingKeyIndex = compDetail.findIndex(
          (Section) => Section === missingSection
        );

        if (missingKeyIndex !== -1 && activeTabIndex == headers.length - 2) {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: `Plaese Fill Required Detail of ${missingSection}`,
            life: 3000,
          });
          handleTabChange({ index: missingKeyIndex }); // Update the active tab index
        }
        setMissingFieldKey(missingOrEmptyKeys[0].key);
        setMissingFieldSection(missingSection);
        //alert("Please fill all the required fields");

        setIsSummaryReady(false);
      } else {
        setMissingFieldKey(null);
      }

      if ((isSummaryValid = true)) {
        setSummary(ss);
        setIsSummaryReady(true);
      }
    }
    if (activeTabIndex == headers.length - 1) {
      debugger;

      let RequestData = {
        new_object: {
          type: selectedCompType,
          //sections: data.tabs,
          sections: Summary,
          visible_to_all: isVisibleToALL,
        },
      };
      // if (!data.tabs["default"]) {
      //   RequestData.new_object.sections.default = {};compType
      // }
      if (!Summary["default"]) {
        RequestData.new_object.sections.default = {};
      }
      const JsonRequest = JSON.stringify(RequestData);
      console.log(JsonRequest);

      const token = access_token;
      axios
        .post(
          "https://esd-api.mosaic-dev.siemens-energy.cloud/api/v1/dev/compV2/create-new-compV2",
          {
            new_object: {
              type: selectedCompType,
              sections: Summary,
              visible_to_all: isVisibleToALL,
            },
          },
          {
            headers: { Authorization: `Bearer ${token}` },
            "content-type": "text/json",
          }
        )
        .then((response) => {
          setShowMessage(true);
          console.log(response);
        })
        .catch((error) => console.log("error", error));
    }
  };
  const getMinval = (val) => {
    const rangeRegex = /DBL\((>=\d+)?(,<=\d+)?\)/;

    const matches = val.match(rangeRegex);
    if (matches) {
      let min = matches[1] ? matches[1].substring(2) : "";
      return min;
    }
  };
  const getMaxVal = (val) => {
    const rangeRegex = /DBL\((>=\d+)?(,<=\d+)?\)/;

    const matches = val.match(rangeRegex);
    if (matches) {
      let max = matches[2] ? matches[2].substring(3) : "";
      return max;
    }
  };

  const getSummary = () => {
    debugger;
    {
      Object.entries(Summary).map((item) => {
        Object.entries(item[1]).map((dd) => {
          Object.entries(dd[1]).map(([key, value]) => {
            debugger;
            return console.log(key);
          });
        });
      });
    }
  };

  const renderNameColumn2 = (keyName) => {
    debugger;
    const detailDataKeysHash = JSON.parse(
      sessionStorage.getItem("detail_data")
    );
    const foundData = detailDataKeysHash.find((data) => data.key === keyName);
    return foundData ? foundData.name : "";
  };

  const renderNameColumn = (rowData, index, section) => {
    debugger;

    const currentErrors = validationErrors[section]?.[rowData[0]];
    const hasErrors = currentErrors && Object.keys(currentErrors).length > 0;

    const detailDataKeysHash = JSON.parse(
      sessionStorage.getItem("detail_data")
    );

    const foundData = detailDataKeysHash.find(
      (data) => data.key === rowData[0]
    );

    //   return(
    //   //<div dangerouslySetInnerHTML={{ __html: foundData.description }} />;
    //   <div>
    //     <DataTable
    //       value={tabledata}
    //       editMode="cell"
    //       tableStyle={{ minWidth: "50rem" }}
    //       editor={(options) => cellEditor(options)} //onCellEditComplete={onCellEditComplete}
    //       footer={renderFooter}
    //     >
    //       {renderColumns(foundData.table_cols)}
    //     </DataTable>
    //   </div>
    //   );
    // } else {
    //return foundData ? foundData.name : '';
    return (
      <div>
        {foundData ? (
          <>
            <div
              style={{ fontSize: "15px" }}
              dangerouslySetInnerHTML={{ __html: foundData.name }}
            ></div>
            <small
              dangerouslySetInnerHTML={{ __html: foundData.description }}
            ></small>
          </>
        ) : (
          ""
        )}
        {hasErrors && (
          <div style={{ color: "red" }}>{Object.values(currentErrors)}</div>
        )}
      </div>
    );
    //}
  };
  const validateData = (rowData) => {
    debugger;
    // Perform your validation logic here
    const errors = {};
    // Example validation: Name should not be empty
    const detailDataKeysHash = JSON.parse(
      sessionStorage.getItem("detail_data")
    );
    const foundData = detailDataKeysHash.find(
      (data) => data.key === rowData[0]
    );
    const isMendatoryData = foundData.mandatory.includes("REQ") ? "Y" : "N";
    const iteminfo = foundData.param_entry_info;

    if (isMendatoryData && rowData[1] == "") {
      errors.name = "Value is required.";
    }
    if (iteminfo.includes("DBL")) {
      if (isNaN(rowData[1])) {
        return (errors.name = "Value must be a number.");
      } else {
        const min = getMinval(iteminfo);
        const max = getMaxVal(iteminfo);

        if (max != "") {
          debugger;
          if (Number(rowData[1]) < min) {
            return (errors.name = `Value must be greater than ${min}`);
          } else if (!(Number(rowData[1]) > min && Number(rowData[1]) < max)) {
            return (errors.name = `Value must be between ${min} and ${max}`);
          }
        }
      }
    }
    // Example validation: Value should be a number

    return errors;
  };

  const textEditor = (options) => {
    return (
      <InputText
        type="text"
        value={options.value}
        onChange={(e) => options.editorCallback(e.target.value)}
      />
    );
  };

  const handleRowEdit = (e, section) => {
    debugger;

    let { index, data, newData, field, originalEvent: event } = e;
    const errors = validateData(newData);
    if (Object.keys(errors).length > 0) {
      setValidationErrors({ [section]: { [data[0]]: errors } });
    } else {
      data[1] = newData[1];
      let sectionKey = data[0];
      Summary[section][`${sectionKey}`] = newData[1];
      //Object.entries(sectionData)[index][1][sectionKey]=newData[1];
      console.log(Summary);
      setValidationErrors({});
    }
  };

  // const renderTable = (columns) => {  debugger
  //   return (
  //     <DataTable value={data} editMode="cell" tableStyle={{ minWidth: '50rem' }}
  //     editor={(options) => cellEditor(options)} //onCellEditComplete={onCellEditComplete}
  //      >
  //       {renderColumns(columns)}
  //     </DataTable>
  //   );
  // };
  const cellEditor = (options) => {
    return textEditor(options);
  };

  const renderColumns = (columns) => {
    debugger;
    const rowNumberColumn = (
      <Column
        key="rowNumber"
        header="#"
        body={rowNumberBodyTemplate}
        style={{ width: "3rem" }}
      />
    );

    const dataColumns = columns.map((column) => (
      <Column
        key={column}
        field={column}
        header={column}
        body={(rowData) => renderInputCell(rowData, column)}
        style={{ width: "20%" }}
      />
    ));

    return [rowNumberColumn, ...dataColumns];
  };
  const renderInputCell = (rowData, field) => {
    return (
      <InputText
        value={rowData[field] || ""}
        onChange={(e) => handleInputChange(e, rowData, field)}
      />
    );
  };
  const handleInputChange = (e, rowData, field) => {
    debugger;
    rowData[field] = e.target.value;
    setData([...tabledata]);
  };

  const addNewRow = () => {
    const newRow = {}; // You can set default values for the new row here, if needed
    setData([...tabledata, newRow]);
  };
  const renderFooter = () => {
    return (
      <div>
        <Button
          className="ml-10"
          icon="pi pi-plus"
          onClick={addNewRow}
          type="button"
        />
      </div>
    );
  };

  const handleTableChange = (newData, sectionKey) => {
    debugger;
    setSummary(`tabs.${sectionKey}.table_data`, newData);
  };

  const renderTableDataColumns = () => {
    const columns = [
      <Column
        header="#"
        body={rowNumberBodyTemplate}
        style={{ minWidth: "3rem" }}
      />,
    ];

    const dataColumns = Object.keys(tabledata[0]).map((key) => (
      <Column field={key} header={key} sortable style={{ minWidth: "13rem" }} />
    ));

    columns.push(...dataColumns);
    return columns;
  };
  const rowExpansionTemplate = (rowData) => {
    debugger;
    const fieldName = rowData[0]; // Assuming rowData[0] holds the value of item[0]

    if (fieldName === "hi_operation_point") {
      const innerData = tabledata; // Assuming rowData[1] holds the data for the inner table
      return (
        <div>
          <DataTable value={innerData}>
            {/* Define your inner data table columns here */}
            {renderTableDataColumns()}
          </DataTable>
        </div>
      );
    }
    return null;
  };
  const handleRowToggle = (e) => {
    setExpandedRows(tabledata);
  };

  const handleRowExpand = (event) => {
    debugger;
    if (event[0] == ["hi_operation_point"]) {
      // let expandedRowsCopy = { ...tabledata };
      // expandedRowsCopy[event.data.id] = !tabledata[event.data.id];
      // setData(expandedRowsCopy);
      setExpandedRows(tabledata);
    }
  };

  const renderExpandIcon = (rowData) => {
    if (rowData[0] === "hi_operation_point") {
      return (
        <Button
          className="p-row-toggler p-link"
          onClick={(e) => handleRowExpand(rowData, e)}
        >
          <i className="pi pi-chevron-right"></i>
        </Button>
      );
    }
    return null;
  };

  const renderValueColumn = (rowData) => {
    const fieldName = rowData[0]; // Assuming rowData[0] holds the value of item[0]

    if (fieldName === "hi_operation_point") {
      const innerData = tabledata; // Assuming rowData[1] holds the data for the inner table
      return (
        <div style={{ marginTop: "1rem" }}>
          <DataTable value={innerData}>
            {/* Define your inner data table columns here */}
            {renderTableDataColumns()}
          </DataTable>
        </div>
      );
    } else {
      // Render the value for rows that don't have "hi_operation_point"
      return (
        <div class="text-center" style={{ marginTop: "1rem" }}>
          {" "}
          {rowData[1]}
        </div>
      );
    }
  };

  const renderInnerDataTable = (rowData) => {
    const fieldName = rowData[0]; // Assuming rowData[0] holds the value of item[0]

    if (fieldName === "hi_operation_point") {
      const innerData = tabledata; // Assuming rowData[1] holds the data for the inner table
      return (
        <div style={{ marginTop: "1rem" }}>
          <DataTable value={innerData}>
            {/* Define your inner data table columns here */}
            {renderTableDataColumns()}
          </DataTable>
        </div>
      );
    }
    return null;
  };

  function Footer(Section) {
    if (selectedCompType.length > 0) {
      const footerList = JSON.parse(
        sessionStorage.getItem("detail_data_type_wise_details")
      )[selectedCompType].filter(
        (item) =>
          item.section == Section &&
          item.footer != null &&
          item.footer != "False" &&
          item.footer != false
      );
      const sortedfooterList = footerList.sort(
        (a, b) => a.orderIndex - b.orderIndex
      );
      console.log(sortedfooterList);

      return (
        <footer
          style={{
            textAlign: "left",
            padding: "1rem",
            borderTop: "1px solid #ccc",
            marginTop: "2rem",
          }}
        >
          <ul>
            {sortedfooterList.map((item) => {
              return (
                <li>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: `${item.footer}`,
                    }}
                  />
                </li>
              );
            })}
          </ul>
        </footer>
      );
    }
  }

  const handleBlur = (section, key, event) => {
    debugger;
    console.log("handle blur entered");
    const fieldValue = event.target.value;
    const rowData = [key, fieldValue];
    const errors = validateData(rowData);
    if (Object.keys(errors).length > 0) {
      setError(key, { type: "custom", message: errors });
    }
  };

  const rowNumberBodyTemplate = (rowData, props) => {
    // Use 'props.rowIndex' to get the row number
    return <span>{props.rowIndex + 1}</span>;
  };

  return props.visibility ? (
    <>
      <Toast ref={toast} />
      <Dialog
        name="createComp"
        header={getHeader}
        visible={props.visibility}
        className="dialog"
        style={{
          width: "80vw",
          backgroundImage: "none",
          backgroundColor: "lightgrey",
        }}
        footer={props.renderFooter("displayBasic")}
        onHide={() => props.onHide("displayCompDialogue", false)}
      >
        <Dialog
          visible={showMessage}
          onHide={() => props.onHide("displayCompDialogue", false)}
          position="top"
          footer={dialogFooter}
          showHeader={false}
          breakpoints={{ "960px": "80vw" }}
          style={{ width: "30vw" }}
        >
          <div className="flex justify-content-center flex-column pt-6 px-3">
            <i
              className="pi pi-check-circle"
              style={{ fontSize: "5rem", color: "var(--green-500)" }}
            ></i>
            <h5>Component Creation is Successful!</h5>
          </div>
        </Dialog>
        <div class="form-row">
          <div class="form-group col-md-6 mt-3 mb-3">
            <label for="inputType" class="siemens-text">
              Select Component Type
            </label>
            <select
              id="inputType"
              class="form-control"
              style={{ height: "6.5vh" }}
              onChange={(e) => setSelectedOption(e.target.value, "order")}
            >
              <option>Choose...</option>
              {compTypeList.map((data, index) => {
                return (
                  <option value={data} key={index}>
                    {data}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <TabView lazy activeIndex={activeTabIndex} onTabChange={handleTabChange}>
            {compDetail.map((Section, index) => {
              const sectionKey =
                Section == null || Section === "" ? "default" : Section;
              console.log(Section);
              return (
                <TabPanel
                  key={index}
                  header={
                    Section == null
                      ? "default"
                      : Section == ""
                      ? "default"
                      : Section
                  }
                  //disabled
                >
                  {compDetailData
                    .filter((data) => data.section == sectionKey)
                    .map((item, index) => {
                      debugger;
                      console.log(isAdminUser);
                      return (
                        <div class="form-row">
                          <div
                            class="form-group col-md-12"
                            dd_section={Section}
                          >
                            {/* <label for={item.key}>
                              {`${item.mandatory}`.includes("REQ")
                                ? `${item.name} *`
                                : `${item.name}`}
                           </label> */}

                            <label
                              for={item.key}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                color: "#8A00E5",
                              }}
                            >
                              <span
                                dangerouslySetInnerHTML={{
                                  __html:
                                    `${item.mandatory}`.includes("REQ")
                                    && ((isAdminUser && adminRequired) || !isAdminUser)
                                      ? `${item.name}`
                                      : `${item.name}`,
                                }}
                              />

                              <span style={{ color: "red" }}>
                                {`${item.mandatory}`.includes("REQ")
                                && ((isAdminUser && adminRequired) || !isAdminUser)
                                  ? `*`
                                  : ``}
                              </span>
                            </label>
                            <label
                              class=" font-italic"
                              style={{ fontSize: "13px" }}
                              dangerouslySetInnerHTML={{
                                __html:
                                  `${item.description}` == null
                                    ? "No Desc"
                                    : `${item.description}`,
                              }}
                            ></label>

                            {item.param_entry_info == "Date" ? (
                              <Controller
                                control={control}
                                name={`tabs[${
                                  Section == null
                                    ? "default"
                                    : Section == ""
                                    ? "default"
                                    : Section
                                }].${item.key}`}
                                //name={`tabs[${index}].${item.name}`} // Store data under 'tabs' array with tab index as the key
                                defaultValue=""
                                rules={{
                                  required: "Date Field 1 is required.",
                                }}
                                render={({ field, fieldState }) => (
                                  <>
                                    <label
                                      htmlFor={field.name}
                                      className={classNames({
                                        "p-error": errors.value,
                                      })}
                                    ></label>
                                    <input
                                      id={field.name}
                                      {...field}
                                      type="date"
                                      data-tab={
                                        item.section == null
                                          ? "default"
                                          : item.section == ""
                                          ? "default"
                                          : item.section
                                      }
                                      placeholder={item.default_value}
                                      className={classNames({
                                        "p-invalid": fieldState.error,
                                      })}
                                      required={
                                        `${item.mandatory}`.includes("REQ") 
                                        && ((isAdminUser && adminRequired) || !isAdminUser)
                                          ? true
                                          : false
                                      }
                                      onBlur={() => trigger(field.name)}
                                      // onBlur={(event) =>
                                      //   handleBlur(
                                      //     item.section,
                                      //     item.key,
                                      //     event
                                      //   )
                                      // }
                                    />
                                    {getFormErrorMessage(field.name)}
                                  </>
                                )}
                              />
                            ) : item.param_entry_info.includes("DBL") ? (
                              <Controller
                                control={control}
                                name={`tabs[${
                                  Section == null
                                    ? "default"
                                    : Section == ""
                                    ? "default"
                                    : Section
                                }].${item.key}`}
                                defaultValue=""
                                render={({ field, fieldState }) => (
                                  <>
                                    <label
                                      htmlFor={field.name}
                                      className={classNames({
                                        "p-error": errors.value,
                                      })}
                                    ></label>
                                    <input
                                      id={field.name}
                                      {...field}
                                      type="number"
                                      min={getMinval(item.param_entry_info)}
                                      max={getMaxVal(item.param_entry_info)}
                                      data-tab={
                                        item.section == null
                                          ? "default"
                                          : item.section == ""
                                          ? "default"
                                          : item.section
                                      }
                                      placeholder={item.default_value}
                                      className={classNames({
                                        "p-invalid": fieldState.error,
                                      })}
                                      required={
                                        `${item.mandatory}`.includes("REQ") && ((isAdminUser && adminRequired) || !isAdminUser)
                                          ? true
                                          : false
                                      }
                                      onBlur={() => trigger(field.name)}
                                    />
                                    {getFormErrorMessage(field.name)}
                                  </>
                                )}
                              />
                            ) : item.param_entry_info.includes("TABLE") ? (
                              <Controller
                                control={control}
                                name={`tabs.${sectionKey}.table_data`}
                                //name={`tabs[${index}].${item.name}`} // Store data under 'tabs' array with tab index as the key
                                defaultValue=""
                                render={({ field, fieldState }) => (
                                  <>
                                    <label
                                      htmlFor={field.name}
                                      className={classNames({
                                        "p-error": errors.value,
                                      })}
                                    ></label>

                                    <DataTable
                                      value={tabledata}
                                      editMode="cell"
                                      tableStyle={{ minWidth: "50rem" }}
                                      editor={(options) => cellEditor(options)} //onCellEditComplete={onCellEditComplete}
                                      footer={renderFooter}
                                    >
                                      {renderColumns(item.table_cols)}
                                    </DataTable>
                                    {/* {renderTable(item.table_cols)}   */}
                                    {getFormErrorMessage(field.name)}
                                  </>
                                )}
                              />
                            ) : item.filter === true &&
                              item.filter_body["category_select_type"] &&
                              (item.filter_body[
                                "category_select_type"
                              ].includes("multi") ||
                                item.filter_body[
                                  "category_select_type"
                                ].includes("single")) ? (
                              <Controller
                                control={control}
                                name={`tabs[${
                                  Section == null
                                    ? "default"
                                    : Section == ""
                                    ? "default"
                                    : Section
                                }].${item.key}`}
                                defaultValue=""
                                rules={{
                                  required: `${item.mandatory}`.includes(
                                    "REQ" && ((isAdminUser && adminRequired) || !isAdminUser)
                                  )
                                    ? "Please select an option"
                                    : "",
                                }}
                                render={({ field, fieldState }) => (
                                  <>
                                    {/* <select
                                      id={field.name}
                                      {...field}
                                      className={classNames("form-control", {
                                        "p-invalid": fieldState.error,
                                      })}
                                      style={{ height: "6.5vh" }}
                                      data-tab={
                                        item.section == null
                                          ? "default"
                                          : item.section == ""
                                          ? "default"
                                          : item.section
                                      }
                                      onBlur={() => trigger(field.name)}
                                    >
                                      <option value="">
                                        {item.default_value}
                                      </option>
                                      {item.filter_body["options"].map(
                                        (data) => (
                                          <option key={data} value={data}>
                                            {data}
                                          </option>
                                        )
                                      )}
                                    </select> */}

                                    <input
                                      id={field.name}
                                      {...field}
                                      className={classNames("form-control", {
                                        "p-invalid": fieldState.error,
                                      })}
                                      //style={{ height: "6.5vh" }}
                                      data-tab={
                                        item.section == null
                                          ? "default"
                                          : item.section == ""
                                          ? "default"
                                          : item.section
                                      }
                                      onBlur={() => trigger(field.name)}
                                      list={`country-list${index}`}
                                      //value={selectedCity}
                                      //onChange={handleCityChange}
                                      placeholder="Select a City"
                                    />
                                    <datalist id={`country-list${index}`}>
                                      {item.filter_body["options"].map(
                                        (data) => (
                                          <option key={data} value={data}>
                                            {data}
                                          </option>
                                        )
                                      )}
                                    </datalist>
                                    {fieldState.error && (
                                      <p className="p-error">
                                        {fieldState.error.message}
                                      </p>
                                    )}
                                  </>
                                )}
                              />
                            ) : (
                              <Controller
                                control={control}
                                name={`tabs[${
                                  Section == null
                                    ? "default"
                                    : Section == ""
                                    ? "default"
                                    : Section
                                }].${item.key}`}
                                //name={`tabs[${index}].${item.name}`} // Store data under 'tabs' array with tab index as the key
                                defaultValue=""
                                render={({ field, fieldState }) => (
                                  <>
                                    <label
                                      htmlFor={field.name}
                                      className={classNames({
                                        "p-error": errors.value,
                                      })}
                                    ></label>
                                    <input
                                      id={field.name}
                                      {...field}
                                      type="text"
                                      data-tab={
                                        item.section == null
                                          ? "default"
                                          : item.section == ""
                                          ? "default"
                                          : item.section
                                      }
                                      placeholder={item.default_value}
                                      className={classNames({
                                        "p-invalid": fieldState.error,
                                      })}
                                      required={
                                        `${item.mandatory}`.includes("REQ") && ((isAdminUser && adminRequired) || !isAdminUser)
                                          ? true
                                          : false
                                      }
                                      onBlur={() => trigger(field.name)}
                                      // onBlur={(event) =>
                                      //   handleBlur(
                                      //     item.section,
                                      //     item.key,
                                      //     event
                                      //   )
                                      // }
                                    />
                                    {/* {getFormErrorMessage(field.name)} */}
                                    {fieldState.error && (
                                      <div className="error-message">
                                        {fieldState.error.message}
                                      </div>
                                    )}
                                  </>
                                )}
                              />
                            )}
                          </div>
                        </div>
                      );
                    })}
                  {Footer(Section)}
                </TabPanel>
              );
            })}
            {selectedCompType.length > 0 && (
              <TabPanel header={"Summary"} disabled>
                {Object.entries(Summary).map((item) => {
                  return (
                    <div>
                      <DataTable
                        value={Object.entries(item[1])}
                        editMode="row"
                        responsiveLayout="scroll"
                        onRowEditInit={(event) => setEditedData(event.data)}
                        onRowEditComplete={(event) =>
                          handleRowEdit(event, item[0])
                        }
                        rowEditorValidator={(rowData) => validateData(rowData)}
                        editable={!validationErrors[item[0]]}
                        expandedRows={tabledata}
                        // onRowToggle={(e) => handleRowToggle}
                        rowExpansionTemplate={rowExpansionTemplate}
                      >
                        {/* <Column
                          body={renderExpandIcon}
                          style={{ width: "3em", textAlign: "center" }}
                        ></Column> */}

                        <Column
                          body={(rowData) =>
                            renderNameColumn(rowData, rowData.index, item[0])
                          }
                          header={item[0] + " (Attributes)"}
                          sortable
                          style={{ minWidth: "20rem" }}
                          editor={(props) => {
                            const rowData = props.rowData;
                            const detailDataKeysHash = JSON.parse(
                              sessionStorage.getItem("detail_data")
                            );
                            const foundData = detailDataKeysHash.find(
                              (data) => data.key === rowData[0]
                            );
                            const name = foundData ? foundData.name : "";
                            return (
                              <div
                                dangerouslySetInnerHTML={{ __html: name }}
                              ></div>
                            );
                          }}
                        ></Column>

                        <Column
                          field="0"
                          header="Description"
                          sortable
                          style={{ minWidth: "16rem", display: "none" }}
                        ></Column>
                        <Column
                          body={(rowData) => renderValueColumn(rowData)}
                          field="1"
                          header="Value"
                          //editor={(options) => textEditor(options)}
                          sortable
                          editor={(props) => {
                            debugger;
                            const rowData = props.rowData;
                            const detailDataKeysHash = JSON.parse(
                              sessionStorage.getItem("detail_data")
                            );
                            const foundData = detailDataKeysHash.find(
                              (data) => data.key === rowData[0]
                            );
                            const filter =
                              foundData.filter == true &&
                              foundData.filter_body.type == "category"
                                ? foundData.filter_body.options
                                : "";
                            debugger;
                            console.log(rowData[0]);
                            if (rowData[0].includes("date")) {
                              return (
                                <input
                                  type="date"
                                  onChange={(e) =>
                                    props.editorCallback(e.target.value)
                                  }
                                />
                              );
                            } else if (filter) {
                              return (
                                <select
                                  //value={options.value}
                                  onChange={(e) =>
                                    props.editorCallback(e.target.value)
                                  }
                                >
                                  {filter.map((option) => (
                                    <option key={option} value={option}>
                                      {option}
                                    </option>
                                  ))}
                                </select>
                              );
                            } else {
                              return textEditor({
                                value: rowData[1],
                                editorCallback: (newValue) =>
                                  props.editorCallback(newValue),
                              });
                            }
                          }}
                          style={{ minWidth: "10rem" }}
                        ></Column>

                        <Column
                          //hidden={item[1]=="hi_operation_point" ? true : false}

                          rowEditor
                          headerStyle={{ width: "5%", minWidth: "5rem" }}
                          bodyStyle={{ textAlign: "center" }}
                        ></Column>
                      </DataTable>

                      {/* <label className="text-center font-weight-bold">
                      {item[0]+" (Attributes)"}
                      </label>
                      {Object.entries(item[1]).map((dd) => {
                        return (
                          <div class="form-row">
                            <div class="form-group col-md-6">
                              <label>{renderNameColumn(dd[0])}</label>
                            </div>
                            <div class="form-group col-md-6">
                              <label>{dd[1]}</label>
                            </div>
                          </div>
                        );
                      })} */}
                    </div>
                  );
                })}
                <div
                  hidden={!isAdminUser ? true : false}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Checkbox
                    checked={isVisibleToALL}
                    className="mr-8"
                    onChange={handleCheckboxClick}
                  />
                  <label htmlFor="checkbox" className="mt-2 ml-2">
                    Component Visible To All{" "}
                  </label>
                </div>
              </TabPanel>
            )}
          </TabView>

          {selectedCompType.length > 0 && (
            <>
              <Button
                type="submit"
                className="primaryBtn  mx-auto d-block mt-3"
                style={{ width: "50vh" }}
              >
                {activeTabIndex == headers.length - 1 ? "Submit" : "Next"}
              </Button>
            </>
          )}
        </form>
      </Dialog>
    </>
  ) : null;
};

export default PartA;
