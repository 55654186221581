import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const Home = (props) => {
  const [isOff, setIsOff] = useState(true);

  useEffect(() => {
    setIsOff(props.showDetailedEvaluationTool);
  },[props.showDetailedEvaluationTool]);

  return (
    <>
      {/* <section className="intro_section page_mainslider ds">
        <div className="flexslider">
          <ul className="slides">
            <li
              data-thumb-alt
              style={{
                width: "100%",
                float: "left",
                marginRight: "-100%",
                position: "relative",
                opacity: 0,
                display: "block",
                zIndex: 1,
              }}
            >
              <img src="assets/images/slide01.jpg" alt="" draggable="false" />
              <div className="container">
                <div className="row">
                  <div className="col-sm-12 text-center">
                    <div className="slide_description_wrapper">
                      <div className="slide_description">
                        <div
                          className="intro-layer"
                          data-animation="slideExpandUp"
                          style={{ visibility: "hidden" }}
                        >
                          <div className="cornered-block">
                            <p className="grey fontsize_20 text-uppercase">
                              Energy as clean as the wind
                            </p>
                            <p className="big grey raleway text-uppercase bold">
                              Clean, Safe, Renewable
                            </p>
                            <p className="grey fontsize_16">
                              Nulla eget ipsum id est accumsan pellentesque nec
                              et odio. Duis sit amet convallis sem, tincidunt
                              feugiat lorem. Nunc porttitor arcu ac dui aliquam
                              efficitur.
                            </p>
                            <Link
                              to={"/"}
                              className="theme_button color1 topmargin_30"
                            >
                              Discover Now
                            </Link>
                            <div className="bottom-corners" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li
              data-thumb-alt
              style={{
                width: "100%",
                float: "left",
                marginRight: "-100%",
                position: "relative",
                opacity: 0,
                display: "block",
                zIndex: 1,
              }}
            >
              <img src="assets/images/slide02.jpg" alt="" draggable="false" />
              <div className="container">
                <div className="row">
                  <div className="col-sm-12 text-center">
                    <div className="slide_description_wrapper">
                      <div className="slide_description">
                        <div
                          className="intro-layer"
                          data-animation="slideExpandUp"
                          style={{ visibility: "hidden" }}
                        >
                          <div className="cornered-block">
                            <p className="grey fontsize_20 text-uppercase">
                              Energy as clean as the wind
                            </p>
                            <p className="big grey raleway text-uppercase bold">
                              Clean, Safe, Renewable
                            </p>
                            <p className="grey fontsize_16">
                              Nulla eget ipsum id est accumsan pellentesque nec
                              et odio. Duis sit amet convallis sem, tincidunt
                              feugiat lorem. Nunc porttitor arcu ac dui aliquam
                              efficitur.
                            </p>
                            <Link
                              to={"/"}
                              className="theme_button color1 topmargin_30"
                            >
                              Discover Now
                            </Link>
                            <div className="bottom-corners" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li
              data-thumb-alt
              style={{
                width: "100%",
                float: "left",
                marginRight: "-100%",
                position: "relative",
                opacity: 0,
                display: "block",
                zIndex: 1,
              }}
            >
              <img src="assets/images/slide03.jpg" alt="" draggable="false" />
              <div className="container">
                <div className="row">
                  <div className="col-sm-12 text-center">
                    <div className="slide_description_wrapper">
                      <div className="slide_description">
                        <div
                          className="intro-layer"
                          data-animation="slideExpandUp"
                          style={{ visibility: "hidden" }}
                        >
                          <div className="cornered-block">
                            <p className="grey fontsize_20 text-uppercase">
                              Energy as clean as the wind
                            </p>
                            <p className="big grey raleway text-uppercase bold">
                              Clean, Safe, Renewable
                            </p>
                            <p className="grey fontsize_16">
                              Nulla eget ipsum id est accumsan pellentesque nec
                              et odio. Duis sit amet convallis sem, tincidunt
                              feugiat lorem. Nunc porttitor arcu ac dui aliquam
                              efficitur.
                            </p>
                            <Link
                              to={"/"}
                              className="theme_button color1 topmargin_30"
                            >
                              Discover Now
                            </Link>
                            <div className="bottom-corners" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li
              data-thumb-alt
              style={{
                width: "100%",
                float: "left",
                marginRight: "-100%",
                position: "relative",
                opacity: 1,
                display: "block",
                zIndex: 2,
              }}
              className="flex-active-slide"
            >
              <img src="assets/images/slide04.jpg" alt="" draggable="false" />
              <div className="container">
                <div className="row">
                  <div className="col-sm-12 text-center">
                    <div className="slide_description_wrapper">
                      <div className="slide_description">
                        <div
                          className="intro-layer animated slideExpandUp"
                          data-animation="slideExpandUp"
                          style={{ visibility: "hidden" }}
                        >
                          <div className="cornered-block">
                            <p className="grey fontsize_20 text-uppercase">
                              Energy as clean as the wind
                            </p>
                            <p className="big grey raleway text-uppercase bold">
                              Clean, Safe, Renewable
                            </p>
                            <p className="grey fontsize_16">
                              Nulla eget ipsum id est accumsan pellentesque nec
                              et odio. Duis sit amet convallis sem, tincidunt
                              feugiat lorem. Nunc porttitor arcu ac dui aliquam
                              efficitur.
                            </p>
                            <Link
                              to={"/"}
                              className="theme_button color1 topmargin_30"
                            >
                              Discover Now
                            </Link>
                            <div className="bottom-corners" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <div className="container nav-container">
            <ol className="flex-control-nav flex-control-paging">
              <li>
                <a
                  href="https://html.modernwebtemplates.com/energy/index.html#"
                  
                >
                  1
                </a>
              </li>
              <li>
                <a
                  href="https://html.modernwebtemplates.com/energy/index.html#"
                  
                >
                  2
                </a>
              </li>
              <li>
                <a
                  href="https://html.modernwebtemplates.com/energy/index.html#"
                  
                >
                  3
                </a>
              </li>
              <li>
                <a
                  href="https://html.modernwebtemplates.com/energy/index.html#"
                  className="flex-active"
                >
                  4
                </a>
              </li>
            </ol>
          </div>
        </div>
      </section> */}

      <section className="ls section_padding_110">
        <div className="container">
          {/* <div className="row">
            <div className="col-md-offset-2 col-md-12 text-center">
              <div className="cornered-heading center-heading bottommargin_40">
                <h2 className="text-uppercase">What we offer</h2>
                <span className="text-uppercase">
                  We offer different Power-To-X services
                </span>
              </div>
              <p>
                <em>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Praesent dapibus erat in tincidunt consequat. Phasellus sit
                  amet convallis turpis, molestie nunc. Nulla facilisi.
                  Vestibulum aliquam convallis ligula.
                </em>
              </p>
            </div>
          </div> */}
          {/* <div className="custom-control custom-switch">
          <input type="checkbox" className="custom-control-input" id="customSwitches" onClick={() => setIsOff(!isOff)} />
          <label className="custom-control-label" htmlFor="customSwitches"></label>
        </div> */}
          <div className="row">
            {/* <div className="col-md-4 col-sm-8 col-sm-offset-2 col-md-offset-0">
              <div
                className="bg_teaser light_teaser with_shadow with_corners text-center topmargin_25"
                style={{ backgroundImage: 'url("./images/teaser01.jpg")' }}
              >
                <div className="bg_overlay" />
                <img src="./images/teaser01.jpg" alt="" />
                <div className="teaser_content">
                  <div className="teaser text-center">
                    <div className="teaser_icon grey size_big">
                      <i className="fa fa-sun-o" />
                    </div>
                    <h4 className="text-uppercase topmargin_25">
                      Basic Evaluation
                    </h4>
                    <hr className="divider_2_40 bottommargin_25" />
                    <p className="darken-font-color basic">
                      Simple tool to quickly identify within seconds the
                      technical and economical viablity of various PtX
                      solutions.
                    </p>
                    <Link
                      to={"/login"}
                      state={{ source: "Basic Evaluation" }}
                      className="removeUnderlineFromLink theme_button color1"
					  target="_blank"
                      href="https://ptx-api.mosaic-dev.siemens-energy.cloud/v1/api/dev/scenario/scenario-list-view"
                    >
                      Launch
                    </Link>
                  </div>
                </div>
              </div>
            </div>*/}
			<div className="col-md-4 col-sm-8 col-sm-offset-2 col-md-offset-0">
              <div
                className="bg_teaser light_teaser with_shadow with_corners text-center topmargin_25"
                style={{ backgroundImage: 'url("./images/teaser03.jpg")' }}
              >
                <div className="bg_overlay" />
                <img src="./images/teaser03.jpg" alt="" />
                <div className="teaser_content">
                  <div className="teaser text-center">
                    <div className="teaser_icon grey size_big">
                      <i className="fa fa-fire-extinguisher" />
                    </div>
                    <h4 className="text-uppercase topmargin_25">
                       Basic Evaluation
                    </h4>
                    <hr className="divider_2_40 bottommargin_25" />
                    <p className="darken-font-color hydrogen">
                      Simple tool to quickly identify within seconds the
                      technical and economical viablity of various PtX
                      solutions.
                    </p>
                    <a rel="noreferrer"
                      className="removeUnderlineFromLink theme_button color1"
                      target="_blank"
                      href="https://ptx-api.mosaic-dev.siemens-energy.cloud/v1/api/dev/scenario/basic-evaluation-landing"
                    >
                      Launch
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {isOff ? (
              <div className="col-md-4 col-sm-8 col-sm-offset-2 col-md-offset-0">
                <div
                  className="bg_teaser light_teaser with_shadow with_corners text-center topmargin_25 hovered"
                  style={{ backgroundImage: 'url("./images/teaser02.jpg")' }}
                >
                  <div className="bg_overlay" />
                  <img src="./images/teaser02.jpg" alt="" />
                  <div className="teaser_content">
                    <div className="teaser text-center">
                      <div className="teaser_icon grey size_big">
                        <i className="fa fa-assistive-listening-systems" />
                      </div>
                      <h4 className="text-uppercase topmargin_25">
                        Detailed system evaluation
                      </h4>
                      <hr className="divider_2_40 bottommargin_25" />
                      <p className="darken-font-color">
                        Simple yet power full tool to size various power-to-x
                        system components and assess the technical and
                        economical aspects of a power-to-x system within
                        minutes.
                      </p>
                      <Link
                        to={"/login"}
                        state={{ source: "Detailed system evaluation" }}
                        className="removeUnderlineFromLink theme_button color1"
                      >
                        Launch
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div></div>
            )}
            <div className="col-md-4 col-sm-8 col-sm-offset-2 col-md-offset-0">
              <div
                className="bg_teaser light_teaser with_shadow with_corners text-center topmargin_25"
                style={{ backgroundImage: 'url("./images/teaser03.jpg")' }}
              >
                <div className="bg_overlay" />
                <img src="./images/teaser03.jpg" alt="" />
                <div className="teaser_content">
                  <div className="teaser text-center">
                    <div className="teaser_icon grey size_big">
                      <i className="fa fa-fire-extinguisher" />
                    </div>
                    <h4 className="text-uppercase topmargin_25">
                      Electrolysis Plant Configurator
                    </h4>
                    <hr className="divider_2_40 bottommargin_25" />
                    <p className="darken-font-color hydrogen">
                      Plant configurator with focus on the electrolysis to
                      assess technical and economical aspects of the
                      power-to-hydrogen system within minutes.
                    </p>
                    <a rel="noreferrer"
                      className="removeUnderlineFromLink theme_button color1"
                      target="_blank"
                      href="https://epc.mosaic-dev.siemens-energy.cloud/home"
                    >
                      Launch
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-sm-8 col-sm-offset-2 col-md-offset-0">
            <img className="version-banner" src="./images/version-banner.png" alt=""/>
              <div
                className="bg_teaser light_teaser with_shadow with_corners text-center topmargin_25"
                style={{ backgroundImage: 'url("./images/teaser03.jpg")' }}
              >
                <div className="bg_overlay" />
                <img src="./images/teaser03.jpg" alt="" />
                <div className="teaser_content">
                  <div className="teaser text-center">
                    <div className="teaser_icon grey size_big">
                      <i className="fa fa-fire-extinguisher" />
                    </div>
                    <h4 className="text-uppercase topmargin_25">
                      myE
                    </h4>
                    <hr className="divider_2_40 bottommargin_25" />
                    <p className="darken-font-color hydrogen">
                      Text
                    </p>
                    <a rel="noreferrer"
                      className="removeUnderlineFromLink theme_button color1"
                      target="_blank"
                      href="/link-einfuegen"
                    >
                      Launch
                    </a>
                  </div>
                </div>
              </div>
            </div>


			<div className="col-md-4 col-sm-8 col-sm-offset-2 col-md-offset-0">
              <div
                className="bg_teaser light_teaser with_shadow with_corners text-center topmargin_25"
                style={{ backgroundImage: 'url("./images/teaser03.jpg")' }}
              >
                <div className="bg_overlay" />
                <img src="./images/teaser03.jpg" alt="" />
                <div className="teaser_content">
                  <div className="teaser text-center">
                    <div className="teaser_icon grey size_big">
                      <i className="fa fa-fire-extinguisher" />
                    </div>
                    <h4 className="text-uppercase topmargin_25">
                      Electrolysis Manufacturing Navigator
                    </h4>
                    <hr className="divider_2_40 bottommargin_25" />
                    <p className="darken-font-color hydrogen">
                      Browse electrolysis stack manufacturing data and predict the potential performance of the stack.
                    </p>
                    <a rel="noreferrer"
                      className="removeUnderlineFromLink theme_button color1"
                      target="_blank"
                      href="https://rca.neb-sb.siemens-energy.cloud/home"
                    >
                      Launch
                    </a>
                  </div>
                </div>
              </div>
            </div>
			<div className="col-md-4 col-sm-8 col-sm-offset-2 col-md-offset-0">
              <div
                className="bg_teaser light_teaser with_shadow with_corners text-center topmargin_25"
                style={{ backgroundImage: 'url("./images/teaser03.jpg")' }}
              >
                <div className="bg_overlay" />
                <img src="./images/teaser03.jpg" alt="" />
                <div className="teaser_content">
                  <div className="teaser text-center">
                    <div className="teaser_icon grey size_big">
                      <i className="fa fa-fire-extinguisher" />
                    </div>
                    <h4 className="text-uppercase topmargin_25">
                      Electrolizer Condition Monitoring
                    </h4>
                    <hr className="divider_2_40 bottommargin_25" />
                    <p className="darken-font-color hydrogen">
                      Grafana based dashboard for condition monitoring of electrolysis plants.
                    </p>
                    <a rel="noreferrer"
                      className="removeUnderlineFromLink theme_button color1"
                      target="_blank"
                      href="https://mye.neb-sb.siemens-energy.cloud/grafana/d/LD5MQRnVz/condition-monitoring?orgId=1"
                    >
                      Launch
                    </a>
                  </div>
                </div>
              </div>
            </div>
			<div className="col-md-4 col-sm-8 col-sm-offset-2 col-md-offset-0">
              <div
                className="bg_teaser light_teaser with_shadow with_corners text-center topmargin_25"
                style={{ backgroundImage: 'url("./images/teaser03.jpg")' }}
              >
                <div className="bg_overlay" />
                <img src="./images/teaser03.jpg" alt="" />
                <div className="teaser_content">
                  <div className="teaser text-center">
                    <div className="teaser_icon grey size_big">
                      <i className="fa fa-fire-extinguisher" />
                    </div>
                    <h4 className="text-uppercase topmargin_25">
                      Q2D Electrolysis Stack Simulator
                    </h4>
                    <hr className="divider_2_40 bottommargin_25" />
                    <p className="darken-font-color hydrogen">
                      Real-time, quasi-2D simulation tool to study the transient behaviour of an electrolysis stack. 
                    </p>
                    <a rel="noreferrer"
                      className="removeUnderlineFromLink theme_button color1"
                      target="_blank"
                      href="https://digitaltwin.neb-sb.siemens-energy.cloud/"
                    >
                      Launch
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
