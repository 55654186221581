import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
//import Checkbox from "react-custom-checkbox";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import * as Icon from "react-icons/fi";
import Dialog from "./Modal";
import { Dialog as ReactDialog } from "primereact/dialog";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { addItem, delItem } from "../redux/actions/index";
import { Toast } from "primereact/toast";
import ProjectCategories from "./projects/ProjectCategories";
import { useNavigate } from "react-router-dom";
import PartA from "./CompForm/PartA";
import { RadioButton } from "primereact/radiobutton";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { Slider } from "primereact/slider";
import "./Dashboard.css";
import ViewCompDetails from "./ViewCompDetails";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Paginator } from "primereact/paginator";
import { createId } from "./projects/Project";
import ProjectDetailModel from "./projects/ProjectDetailModel";
import { Badge } from "primereact/badge";
import { InputSwitch } from "primereact/inputswitch";
import "./Cards.css";
import { useLocation } from "react-router-dom";
import { Avatar } from "primereact/avatar";

const Dashboard = () => {
  const [loadingStatus, setLoadingStatus] = useState({});
  const access_token = localStorage.getItem("accessToken");
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const toast = useRef(null);
  const [componentList, setComponentList] = useState([]);
  // eslint-disable-next-linee
  const [page, setPage] = useState(1);
  const [componentSearch, setComponentSearch] = useState("");
  const [componentType, setcomponentType] = React.useState([]);
  const [retriveComponentType, setretriveComponentType] = useState("");
  const [componentDetail, setcomponentDetail] = useState();
  const [compViewDetails, setcompViewDetail] = useState();
  const [size, setSize] = useState(10);
  const [projectDetail, setProjectDetail] = useState(false);
  const [order, setOrder] = useState(false);
  // eslint-disable-next-line
  const [sizeDropdown, setSizeDropdown] = useState([10, 20, 30, 40, 50]);
  // eslint-disable-next-line
  const [orderDropdown, setorderDropdown] = useState([
    "Default",
    "Oldest",
    "Newest",
  ]);
  const compFilterTypeList = [
    { name: "ALL", key: "A" },
    { name: "OWNED", key: "B" }
    //{ name: "DEFAULT", key: "C" },
  ];

  const [projectDropdown, setproejectDropDown] = useState([]);
  const [compFilterOwnedBy, setcompFilterOwnedBy] = useState(
    compFilterTypeList[0]
  );
  const [power, setPower] = useState(50);
  const [capacity, setCapacity] = useState(50);

  const [displayBasic, setDisplayBasic] = useState(false);
  const [displayCompDialogue, setDisplayCompDialog] = useState(false);
  const [Btntype, setBtnType] = useState(false);
  const dispatch = useDispatch();
  // eslint-disable-next-line
  const [cartBtn, setCartBtn] = useState("Add to Project");
  const userRole = localStorage.getItem("userRole");
  const compTypeList = ["Li-Ion Battery", "Heat Pump"];
  const [filterOptions, setFilterOptions] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [SelectedSliderValue, setSelectedSliderValue] = useState([]);
  const [rangeValue, setRangeValue] = useState("");
  const [minSliderValue, setminSliderValue] = useState([]);
  const [maxSliderValue, setmaxSliderValue] = useState([]);
  const cardsPerPage = 8;
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastCard = currentPage * cardsPerPage;
  const indexOfFirstCard = indexOfLastCard - cardsPerPage;
  const currentCards = componentList.slice(indexOfFirstCard, indexOfLastCard);
  const [productDialog, setProductDialog] = useState(false);
  const [selectedComp, setSelectedComp] = useState(null);
  const [NewProductName, setNewProductName] = useState("");
  const [displayProject, setDisplayProject] = useState(false);
  const [cartValue, setcartValue] = useState(0);
  const [showFilter, setshowFilter] = useState(false);
  const [buttonText, setbuttonText] = useState();
  const [addedStatus, setAddedStatus] = useState({});
  const [isAdded, setIsAdded] = useState(false);
  const currentPath = window.location.pathname;
  const pathParts = currentPath.split("/");
  const [badgeCount, setBadgeCount] = useState(0);

  const isProjectPath =
    pathParts[pathParts.length - 1].toLowerCase() == "dashboard"
      ? "false"
      : "true";

  var userRoleList = userRole.split(",");
  var adminUser = userRoleList.indexOf("ADMIN");
  if (adminUser > 0) {
    localStorage.setItem("AdminUser", true);
  }
  console.log("Dashboard status", adminUser);
  let emptyProduct = {
    id: null,
    name: "",
    created_by: null,
  };
  const [product, setProduct] = useState(emptyProduct);

  const dialogFuncMap = {
    displayBasic: setDisplayBasic,
    Btntype: setBtnType,
    displayCompDialogue: setDisplayCompDialog,
    displayProject: setDisplayProject,
  };

  const accordionData = {
    title: "Section 1",
    content: `Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quis sapiente
        laborum cupiditate possimus labore, hic temporibus velit dicta earum
        suscipit commodi eum enim atque at? Et perspiciatis dolore iure
        voluptatem.`,
  };
  // eslint-disable-next-line//
  const { title, content } = accordionData;
  const [isActive, setIsActive] = useState(true);
  const [isActiveSavedComp, setIsActiveSavedComp] = useState(true);
  const [Submitted, setSubmitted] = useState(false);

  useEffect(() => {
    getProjectWithID(params.name);
  }, []);

  useEffect(() => {
    if (
      localStorage.getItem("accessToken") != null &&
      localStorage.getItem("accessToken") != ""
    ) {
      //added by neha for view detail change as now se.ctions are introduced.
      getComponentList(size, order);
      getComponentType();

      getProjects();
    } else {
      navigate("/login");
    }
    // eslint-disable-next-line
  }, []);

  // useEffect(() => {
  //   debugger;
  //   if (product.id != undefined) getProjectWithID(product.id);
  // }, []);
  useEffect(() => {}, [cartValue]);

  useEffect(() => {
    if (
      retriveComponentType.length ||
      componentSearch.length ||
      compFilterOwnedBy.length
    ) {
      getSearchResult(componentSearch);
      //getSearchResult(selectedFilters);
      fetchFilterOptions();
    }
    // eslint-disable-next-line
  }, [retriveComponentType, componentSearch, compFilterOwnedBy]);

  useEffect(() => {
    debugger;
    getSearchResult(selectedFilters.length > 0 ? selectedFilters : "");
  }, [selectedFilters]);

  // useEffect(() => {
  //   console.log(addedStatus);
  // }, [addedStatus]);

  useEffect(() => {
    const checkComponentsInProject = async () => {
      const updatedStatus = {};

      for (const comp of currentCards) {
        const response = projectDetail.comps.filter(
          (item) => item.id == comp.id
        );
        // Update the added status based on the API response
        if (response.length > 0) {
          updatedStatus[comp.id] = true;
        } else {
          updatedStatus[comp.id] = false;
        }
      }
      setAddedStatus(updatedStatus);
    };
    if (projectDetail && projectDetail.comps) {
      checkComponentsInProject();
    }
    getComponentList(order, size);
  }, [currentPage, projectDetail]);

  const fetchFilterOptions = () => {
    let filterOptionsResponse = {};
    const filterData = JSON.parse(
      sessionStorage.getItem("detail_data_keys_filter")
    );
    if (filterData[retriveComponentType]) {
      //setFilterOptions(filterData[retriveComponentType]);
      const updatedFilterOptions = {
        retriveComponentType: retriveComponentType,
        filteredData: filterData[retriveComponentType],
      };
      setFilterOptions(updatedFilterOptions);
    }
  };

  const getProjects = () => {
    const token = access_token;
    axios
      .get(
        "https://esd-api.mosaic-dev.siemens-energy.cloud/api/v1/dev/project/get-project-user?" +
          "&verbose_comp=" +
          true +
          "&sverbose_note=" +
          false +
          "&page=" +
          1 +
          "&size=" +
          20 +
          "&order=" +
          false,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((res) => {
        setproejectDropDown(res.data);
      });
  };

  const getProjectWithID = async (projectId) => {
    try {
      if (projectId == null) {
        projectId = product.id;
      }

      const token = access_token;
      const response = await axios.get(
        "https://esd-api.mosaic-dev.siemens-energy.cloud/api/v1/dev/project/get-project/" +
          projectId,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      // .
      // then((res) => {
      // console.log(res, "res");
      setProjectDetail(response.data);
      setProduct({
        id: response.data.id,
        name: response.data.name,
      });
      setBadgeCount(response.data.comps?response.data.comps.length:0);

      // })
      localStorage.setItem("project", JSON.stringify(response.data));

      //});
      return response.data;
    } catch (error) {
      //return null;
      console.log(error);
    }
  };

  const isProductAdded = (compID) => {
    const productAdded =
      projectDetail.comps != undefined
        ? projectDetail.comps.filter((item) => item.id == compID)
        : "";
    if (productAdded.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const getComponentType = () => {
    const token = access_token;
    axios
      .get(
        "https://esd-api.mosaic-dev.siemens-energy.cloud/api/v1/dev/comp/comp-detail-type",
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((res) => {
        setcomponentType(res.data);
      });
  };

  const handleChange = (e) => {
    debugger;
    const { checked, value } = e;

    if (checked) {
      //setretriveComponentType([...retriveComponentType, value]);
      setshowFilter(true);
      setretriveComponentType(value);
      setSelectedFilters([]);
    } else {
      setretriveComponentType((prev) => prev.filter((res) => res !== value));
    }
  };

  const getComponentList2 = (pageSize, pageOrder) => {
    const token = access_token;

    axios

      .get(
        "https://esd-api.mosaic-dev.siemens-energy.cloud/api/v1/dev/comp/get-comp-filter?" +
          "&comp_view=" +
          "ALL" +
          "&search=" +
          "" +
          "&regions=" +
          "" +
          "&types=" +
          "" +
          "&page=" +
          page +
          "&size=100" +
          //pageSize +
          "&order=" +
          pageOrder,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((res) => {
        setComponentList(res.data);
      });
  };

  const getComponentList = (pageSize, pageOrder) => {
    debugger;
    const token = access_token;

    axios
      .post(
        "https://esd-api.mosaic-dev.siemens-energy.cloud/api/v1/dev/compV2/get-comp-filterV2",
        {
          comp_filter_schema: {
            search: "",
            comp_view: "ALL",
            filters: [],
            types: "",
            page: 1,
            size: 50, //pageSize,
            order: pageOrder,
          },
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((res) => {
        //setcompViewDetail(res.data);
        setComponentList(res.data);
      });
  };

  const setSelectedOption = (e, flag) => {
    if (flag === "size") {
      setSize(e);
      getComponentList(e, order);
    } else {
      if (e === "Newest") {
        setOrder(false);
        getComponentList(size, false);
      } else {
        setOrder(true);
        getComponentList(size, true);
      }
    }
  };

  const getSearchResult2 = (e) => {
    debugger;
    setComponentSearch(e);
    const token = access_token;
    axios
      .get(
        "https://esd-api.mosaic-dev.siemens-energy.cloud/api/v1/dev/compV2/get-comp-filter?search=" +
          e +
          "&comp_view=" +
          compFilterOwnedBy +
          "&regions=" +
          "" +
          "&types=" +
          retriveComponentType +
          "&page=" +
          page +
          "&size=" +
          size +
          "&order=" +
          order,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((res) => {
        setComponentList(res.data);
      });
  };

  const getSearchResult = (e) => {
    debugger;
    //setComponentSearch(e);
    const filtersForAPI = selectedFilters.map((filter) => {
      return {
        section: filter.section,
        filter_type: filter.filter_type,
        key: filter.key,
        options: filter.options,
      };
    });

    const token = access_token;
    axios
      .post(
        "https://esd-api.mosaic-dev.siemens-energy.cloud/api/v1/dev/compV2/get-comp-filterV2",
        {
          comp_filter_schema: {
            search: filtersForAPI.length > 0 ? "" : e,
            comp_view: compFilterOwnedBy.name,
            types: retriveComponentType.length == 0 ? "" : retriveComponentType,
            page: page,
            size: size,
            order: order,
            filters: filtersForAPI.length > 0 ? filtersForAPI : [],
          },
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((res) => {
        setComponentList(res.data);
      });
  };

  const onClick = (name, compDetail, Btntype) => {
    setcomponentDetail(compDetail);
    dialogFuncMap[`${name}`](true);
    debugger;
    dialogFuncMap["Btntype"](Btntype);
  };

  const onHide = (name, ifReloaded) => {
    debugger;
    dialogFuncMap[`${name}`](false);
    if (ifReloaded.ifReloaded === true || name === "displayCompDialogue") {
      window.location.reload();
    }
  };

  const openNew = () => {
    emptyProduct.name = createId();
    setNewProductName(emptyProduct.name);
    setProduct(emptyProduct);
    setSubmitted(false);
    setProductDialog(true);
  };

  const handleCart = async (e, comp) => {
    setLoadingStatus((prevStatus) => ({ ...prevStatus, [comp.id]: true }));

    e.preventDefault();
    debugger;
    setSubmitted(true);
    debugger;

    let data = {};
    if (product.id != null || params.name != undefined) {
      data = await getProjectWithID(
        params.name != undefined ? params.name : product.id
      );
    }
    const updatedProjectDetail = data || {};
    localStorage.setItem("project", updatedProjectDetail);
    let cartData = JSON.parse(localStorage.getItem("cart") || "[]");
    cartData.push(comp);
    localStorage.setItem("cart", JSON.stringify(cartData));
    setSelectedComp(comp);
    let flag = 1;
    if (e.target.innerText == "Remove") {
      debugger;
      const updatedProjectComps = data.comps.filter(
        (item) => item.id !== comp.id
      );
      const ProjectCompID = updatedProjectComps.map((comp) => {
        return comp.id;
      });
      let projectid = product.id;
      let projectName = projectDetail.name;
      axios
        .put(
          "https://esd-api.mosaic-dev.siemens-energy.cloud/api/v1/dev/project/update-project/" +
            projectid,
          {
            project: {
              name: projectName,
              comps: ProjectCompID,
              notes: [],
            },
          },
          { headers: { Authorization: `Bearer ${access_token}` } }
        )
        .then((response) => {
          toast.current.show({
            severity: "success",
            summary: "Successful",
            detail: "Component deleted from this Project",
            life: 3000,
          });

          setLoadingStatus((prevStatus) => ({
            ...prevStatus,
            [comp.id]: false,
          }));
          setAddedStatus((prevStatus) => {
            const updatedStatus = { ...prevStatus };

            updatedStatus[comp.id] = false; // Remove the comp.id from the state
            return updatedStatus;
          });
          getProjectWithID(projectid);
          localStorage.setItem("cart", "[]");
        });
    } else if (e.target.innerText == "Add") {
      saveProduct();

      setAddedStatus((prevStatus) => {
        const updatedStatus = { ...prevStatus };
        updatedStatus[comp.id] = true; // Add the comp.id back to the state
        return updatedStatus;
      });
      setLoadingStatus((prevStatus) => ({ ...prevStatus, [comp.id]: false }));
    }
  };

  const renderFooter = (name) => {
    return (
      <div>
        {/* <Button label="Close" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" autoFocus/> */}
      </div>
    );
  };

  const getOwnedBy = (created_by, isElevatedUser) => {
    if (isElevatedUser) {
      return "ADMIN";
    } else if (created_by === localStorage.getItem("loggedInUser")) {
      return "SELF";
    } else {
      return "OTHER USER";
    }
  };

  const reject = () => {
    // toast.current.show({
    //   severity: "warn",
    //   summary: "Rejected",
    //   detail: "You have rejected",
    //   life: 3000,
    // });
  };

  const deleteComponent = (compID) => {
    debugger;
    const token = access_token;
    axios
      .delete(
        "https://esd-api.mosaic-dev.siemens-energy.cloud/api/v1/dev/compV2/delete-compV2/" +
          compID,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Component Deleted from Database",
          life: 3000,
        });
        getComponentList(size, order);
        //window.location.reload();
      });
  };

  const handleChange2 = (e) => {
    debugger;
    const { checked, value } = e;

    if (checked.checked) {
      setcompFilterOwnedBy(value);
      setSelectedFilters([]);
      setSelectedSliderValue([]);
      setretriveComponentType([]);
    } else {
      setcompFilterOwnedBy((prev) => (prev === value.name ? "" : value.name));
    }
  };

  const handleFilterChange = (event) => {
    debugger;
    const selectedFilterId = {
      section: event.section,
      filter_type: event.filter_type,
      key: event.key,
      options: [event.value],
    };

    if (event.checked) {
      // Add the selected filter to the selectedFilters state
      //setSelectedFilters([...selectedFilters, selectedFilterId]);
      const updatedFilters = selectedFilters.filter(
        (filterId) =>
          filterId.section !== selectedFilterId.section ||
          filterId.filter_type !== selectedFilterId.filter_type ||
          filterId.key !== selectedFilterId.key
      );

      setSelectedFilters(updatedFilters);

      // Add the selected filter to the selectedFilters state
      setSelectedFilters((prevFilters) => [...prevFilters, selectedFilterId]);
      //getSearchResult(selectedFilters,"FilterType");
    } else {
      // Remove the selected filter from the selectedFilters state
      const updatedFilters = selectedFilters.filter(
        (filterId) => filterId !== selectedFilterId
      );
      setSelectedFilters(updatedFilters);
    }
  };

  const handleCheckboxChange = (event) => {
    const selectedFilterId = {
      section: event.section,
      filter_type: event.filter_type,
      key: event.key,
      options: [event.value],
    };
    // Logic for multi-select checkboxes
    setSelectedFilters((prevFilters) => {
      const updatedFilters = prevFilters.map((filterId) => {
        if (
          filterId.section === selectedFilterId.section &&
          filterId.filter_type === selectedFilterId.filter_type &&
          filterId.key === selectedFilterId.key
        ) {
          const updatedOptions = event.checked.checked
            ? [...filterId.options, event.value]
            : filterId.options.length > 1
            ? filterId.options.filter((option) => option !== event.value)
            : [];

          return {
            ...filterId,
            options: updatedOptions,
          };
        }
        return filterId;
      });

      const existingFilter = updatedFilters.find(
        (filterId) =>
          filterId.section === selectedFilterId.section &&
          filterId.filter_type === selectedFilterId.filter_type &&
          filterId.key === selectedFilterId.key
      );

      if (existingFilter) {
        // If the filter already exists, update its options
        //existingFilter.options = selectedFilterId.options;
      } else {
        // Otherwise, add the new filter
        updatedFilters.push(selectedFilterId);
      }

      return updatedFilters;
    });
  };

  const handleSliderChange = (event, key) => {
    const newSelectedValues = [...SelectedSliderValue];
    newSelectedValues[key] = event.value.value;
    setSelectedSliderValue(newSelectedValues);

    const selectedFilterId = {
      section: event.section,
      filter_type: event.filter_type,
      key: event.key,
      options: [event.minRange, event.value.value],
    };

    const updatedFilters = selectedFilters.filter(
      (filterId) =>
        filterId.section !== selectedFilterId.section ||
        filterId.filter_type !== selectedFilterId.filter_type ||
        filterId.key !== selectedFilterId.key
    );

    setSelectedFilters(updatedFilters);

    // Add the selected filter to the selectedFilters state
    setSelectedFilters((prevFilters) => [...prevFilters, selectedFilterId]);
  };

  const getfilter = () => {
    debugger;
    if (filterOptions.filteredData) {
      const filtered = filterOptions.filteredData.filter(
        (a) => a.type == "category"
      );
      const filteredRange = filterOptions.filteredData.filter(
        (a) => a.type == "range"
      );
      const filterElements = [];

      for (let entry in filtered) {
        if (filtered[entry].hasOwnProperty("options")) {
          const filterKey = filtered[entry].key;
          const FilterName = JSON.parse(sessionStorage.detail_data_keys_hash)[
            filterKey
          ].name;
          const htmlContent = `  
            ${FilterName}  `;
          // <span style="float: right;">${isActive ? "-" : "+"}</span>

          //filterElements.push(<label>{FilterName}</label>);
          filterElements.push(
            <div className="accordion-item">
              <div
                id={filtered[entry]}
                className="accordion-title"
                //onClick={() => setIsActive(!isActive)}
              >
                <div
                  style={{
                    cursor: "pointer",
                    fontWeight: "bold",
                    fontSize: "18px",
                    color: "#8A00E5",
                  }}
                  dangerouslySetInnerHTML={{ __html: htmlContent }}
                ></div>
              </div>
            </div>
          );

          if (filtered[entry].category_select_type == "single") {
            filtered[entry].options.forEach((value, index) => {
              const inputId = `${entry}_${index}`;
              filterElements.push(
                <>
                  <div className="flex align-items-center">
                    <RadioButton
                      inputId={inputId}
                      name={`filter_${entry}`}
                      value={value}
                      type="radio"
                      style={{
                        overflow: "hidden",
                        marginRight: "4px",
                        marginTop: "0px",
                      }}
                      icon={
                        -(
                          <div
                            style={{
                              display: "flex",
                              flex: 1,
                              backgroundColor: "#174A41",
                              alignSelf: "stretch",
                            }}
                          >
                            <Icon.FiCheck color="white" size={20} />
                          </div>
                        )
                      }
                      onChange={(Value) => {
                        let selectedValues = "";

                        let p = {
                          checked: Value,
                          value:
                            selectedValues.length > 0 ? selectedValues : value,
                          section: filtered[entry].section,
                          filter_type: filtered[entry].type,
                          key: filtered[entry].key,
                          type: filtered[entry].category_select_type,
                        };
                        return handleFilterChange(p);
                      }}
                      //checked={selectedFilters.includes(value)}
                      checked={selectedFilters.some((filter) =>
                        filter.options.includes(value)
                      )}
                    />
                    <label htmlFor={value} className="ml-2">
                      {value}
                    </label>
                  </div>
                </>
              );
            });
          }

          if (filtered[entry].category_select_type == "multi") {
            filterElements.push(  
              <React.Fragment>  
                <div  
                  style={{  
                    maxHeight: "200px",  
                    overflowY: "scroll",  
                  }}  
                >  
                  {filtered[entry].options.map((value, index) => {  
                    const inputId = `${entry}_${index}`;  
                    return (  
                      <div  
                        id={`div_${index}`}  
                        style={{  
                          display: "flex",  
                          alignItems: "center",  
                          flexWrap: "inherit",  
                        }}  
                      >  
                        <Checkbox  
                          inputId={inputId}  
                          name={`checkbox_${entry}`}  
                          value={value}  
                          onChange={(Value) => {  
                            let p = {  
                              checked: Value,  
                              value: value,  
                              section: filtered[entry].section,  
                              filter_type: filtered[entry].type,  
                              key: filtered[entry].key,  
                            };  
                            return handleCheckboxChange(p);  
                          }}  
                          //checked={selectedFilters.includes(value)}  
                          checked={selectedFilters.some((filter) =>  
                            filter.options.includes(value)  
                          )}  
                        />  
                        <label htmlFor={value} className="ml-2">  
                          {value}  
                        </label>  
                      </div>  
                    );  
                  })}  
                </div>  
              </React.Fragment>  
            );  
            
          }
        }
      }

      const SliderMinValue = filteredRange.reduce((acc, { key, options }) => {
        acc[key] = parseInt(options.range_min, 10);
        return acc;
      }, {});

      for (let entry in filteredRange) {
        debugger;
        if (filteredRange[entry].hasOwnProperty("options")) {
          const filterKey = filteredRange[entry].key;
          const FilterName = JSON.parse(sessionStorage.detail_data_keys_hash)[
            filterKey
          ].name;

          filterElements.push(
            <>
              <div className="accordion-content mt-2 filter__title">
                <div
                  style={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "#8A00E5",
                    marginTop: "2rem",
                  }}
                  dangerouslySetInnerHTML={{ __html: FilterName }}
                ></div>
                <div className="w-14rem mt-2">
                  <InputNumber
                    value={
                      SelectedSliderValue[filteredRange[entry].key] != undefined
                        ? SelectedSliderValue[filteredRange[entry].key]
                        : SliderMinValue[filteredRange[entry].key]
                    }
                    onChange={(e) =>
                      handleSliderChange(e, filteredRange[entry].key)
                    }
                    className="w-full"
                  />
                  <Slider
                    key={entry.key}
                    value={SelectedSliderValue[filteredRange[entry].key]}
                    //min={parseInt(filteredRange[entry].options.range_min)}
                    //max={parseInt(filteredRange[entry].options.range_max)}
                    range
                    //step={1}
                    //onChange={(e) => handleSliderChange(e, index)}
                    onChange={(Value) => {
                      let p = {
                        value: Value,
                        section: filteredRange[entry].section,
                        filter_type: filteredRange[entry].type,
                        key: filteredRange[entry].key,
                        minRange: parseInt(
                          filteredRange[entry].options.range_min
                        ),
                      };
                      return handleSliderChange(p, filteredRange[entry].key);
                    }}
                    className="p-mb-3"
                  />
                </div>
              </div>
            </>
          );
        }
      }
      return <div>{filterElements}</div>;
    }
  };

  const onPageChange = (event) => {
    debugger;
    setCurrentPage(event.page + 1);
  };

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _product = { ...product };
    _product[`${name}`] = val;

    setProduct(_product);
  };

  const hideDialog = () => {
    debugger;
    setProductDialog(false);
    setSubmitted(false);
    //emptyProduct.name = "";
    setProduct(emptyProduct);
  };

  async function saveProduct() {
    debugger;

    emptyProduct.name = NewProductName;
    let cartComp = JSON.parse(localStorage.getItem("cart"));
    const project = projectDetail.id; //JSON.parse(localStorage.getItem("project"))||{};
    let comp_Array = [];
    cartComp.map((res) => comp_Array.push(res.id));
    let new_Array = [];
    if (project != null) {
      projectDetail.comps.map((res) => new_Array.push(res.id));
    }
    let final_Array = [];
    final_Array = comp_Array.concat(new_Array);

    const token = access_token;

    let cartData = JSON.parse(localStorage.getItem("cart"));
    let flag = 1;

    debugger;
    let projectid = `${product.id == null ? params.name : product.id}`;
    axios
      .put(
        "https://esd-api.mosaic-dev.siemens-energy.cloud/api/v1/dev/project/update-project/" +
          projectid,
        {
          project: {
            name: params.name ? projectDetail.name : product.name,
            comps: final_Array,
            notes: [],
          },
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        //getProjects();
        getProjectWithID(projectid);
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Project Updated",
          life: 3000,
        });
        setSelectedComp(null);

        setcartValue(cartValue + 1);

        localStorage.setItem("cart", "[]");

        // htmlBtn.innerText = "Remove";
        // htmlBtn.style.backgroundColor = "Red";
      });

    setProductDialog(false);
  }

  const ResetFilter = (e) => {
    setshowFilter(false);
    setSelectedFilters([]);
    setSelectedSliderValue([]);
    setretriveComponentType([]);
  };

  const productDialogFooter = (
    <React.Fragment>
      <Button
        label="Cancel"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDialog}
      />
      <Button
        label="Save"
        icon="pi pi-check"
        className="p-button-text"
        onClick={saveProduct}
      />
    </React.Fragment>
  );

  const getKeyname = (key) => {
    const keyName = JSON.parse(sessionStorage.getItem("detail_data_keys_hash"))[
      key
    ].name;
    return (
      <label
        style={{ fontSize: "1rem", marginTop: "0px", marginBottom: "0px" }}
        dangerouslySetInnerHTML={{ __html: keyName }}
      ></label>
    );
  };

  const showOnCardData = (category) => {
    debugger;
    const sessionData = sessionStorage.getItem("detail_data_type_wise_details");
    let filteredData,
      showOnCard = "";
    if (sessionData != null) {
      filteredData = JSON.parse(
        sessionStorage.getItem("detail_data_type_wise_details")
      )[category];

      showOnCard = filteredData
        .filter((item) => item.show_on_card)
        .map((item) => {
          return {
            key: item.key,
            section: item.section,
            name: getKeyname(item.key),
          };
        });
    }
    return showOnCard;
  };

  return (
    <>
      {/*
        <Dialog
          visibility={displayBasic}
          compData={componentDetail}
          renderFooter={renderFooter}
          onHide={onHide}
          Btntype={Btntype}
          />*/}

      <Toast ref={toast} />
      <ProjectDetailModel
        visibility={displayProject}
        compData={projectDetail}
        onHide={() => onHide("displayProject", true)}
      />
      <ViewCompDetails
        visibility={displayBasic}
        compData={componentDetail}
        renderFooter={renderFooter}
        onHide={onHide}
        Btntype={Btntype}
      />

      <PartA
        visibility={displayCompDialogue}
        onHide={onHide}
        renderFooter={renderFooter}
      />
      {/* nehc changes 9 oct */}
      <ReactDialog
        visible={productDialog}
        style={{ width: "450px" }}
        header="Undefined Project"
        modal
        className="p-fluid"
        footer={productDialogFooter}
        onHide={hideDialog}
      >
        {product.image && (
          <img
            src={`images/product/${product.image}`}
            onError={(e) =>
              (e.target.src =
                "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
            }
            alt={product.image}
            className="product-image block m-auto pb-3"
          />
        )}
        <div className="field mt-2">
          <InputText
            id="name"
            value={product.name}
            onChange={(e) => onInputChange(e, "name")}
            required
            autoFocus
            //className={classNames({ "p-invalid": submitted && !product.name })}
          />
          {/* {submitted && !product.name && (
              <small className="p-error">Name is required.</small>
            )} */}
        </div>
        <br></br>
        {/* <div className="row float-left ml-1">
            <Button label="Add Component" icon="pi pi-plus" iconPos="right" />
          </div> */}
        {/* <div className="row float-right mr-1">
            <Button
              label="Save As Project"
              icon="pi pi-download"
              iconPos="right"
              onClick={()=>saveProduct()}
            />
          </div> */}
      </ReactDialog>

      <Toast ref={toast} />
      <ConfirmDialog />
      <div className="block-split block-split--has-sidebar">
        <div className="container-fluid mx-4">
          <div className="block-split__row row no-gutters mb-2">
            <div className="block-split__item block-split__item-sidebar col-auto">
              <div className="sidebar sidebar--offcanvas--mobile">
                <div className="sidebar__body">
                  {" "}
                  <div className="sidebar__content ml-2 mt-4">
                    <div
                      style={{
                        position: "relative",
                        display: "inline-block",
                      }}
                    >
                      <label
                        style={{
                          fontWeight: "bold",
                          fontSize: "18px",
                          color: "#8A00E5",
                          overflow: "break-word", // or use overflowWrap: "break-word"
                          maxWidth: "100%", // Limit the width to prevent overflow
                        }}
                      >
                        {product.name}
                      </label>
                      {/* {product.name &&
                      <Badge
                        value={badgeCount}
                        style={{
                          position: "absolute",
                          top: "-8px",
                          right: "5px",                          
                          cursor: "pointer",
                        }}
                        onClick={() => setDisplayProject(true)}
                      />
                    } */}
                      {product.name.length > 0 ? (
                        <span>
                          <a onClick={() => setDisplayProject(true)}>
                            <i
                              className="pi pi-eye ml-2"
                              style={{ fontSize: "1.5em" }}
                              title="View Project"
                            ></i>
                            {/* <Badge value={cartValue} severity="danger" />  */}
                          </a>
                        </span>
                      ) : (
                        ""
                      )}
                    </div>

                    {/* {projectDetail.name == undefined
                          ? product.name
                          : projectDetail.name} */}

                    <div
                      style={{
                        marginTop: "9%",
                        cursor: "pointer",
                        fontWeight: "bold",
                        fontSize: "17px",
                        color: "#8A00E5",
                        marginBottom: 0,
                      }}
                      className="d-flex text-siemens"
                    >
                      Filters
                    </div>

                    <div className="widget widget-filters widget-filters--offcanvas--mobile">
                      <div
                        className="widget-filters__item"
                        style={{ marginTop: "0%" }}
                      >
                        <div className="accordion-item">
                          <div
                            className="accordion-title"
                            onClick={() => setIsActive(!isActive)}
                          >
                            <div
                              style={{
                                cursor: "pointer",
                                fontWeight: "bold",
                                fontSize: "18px",
                                color: "#8A00E5",
                              }}
                            >
                              Search Comp{" "}
                              <span style={{ float: "right" }}>
                                {isActive ? "-" : "+"}
                              </span>
                            </div>
                          </div>
                          {isActive && (
                            <div
                              className="accordion-content mt-2 filter__title"
                              style={{
                                maxHeight: "260px",
                                overflowY: "scroll",
                                padding: "18px",
                                lineHeight: 2,
                              }}
                            >
                              {compFilterTypeList.map((res, index) => {
                                return (
                                  <>
                                    <div key={index}>
                                      <RadioButton
                                        inputId={`SearchComp_${index}`}
                                        //name={`SearchComp${res.key}`}
                                        value={res}
                                        checked={
                                          compFilterOwnedBy.key === res.key
                                        }
                                        type="radio"
                                        style={{
                                          overflow: "hidden",
                                          marginRight: "4px",
                                          marginTop: "0px",
                                        }}
                                        icon={
                                          <div
                                            style={{
                                              display: "flex",
                                              flex: 1,
                                              backgroundColor: "#174A41",
                                              alignSelf: "stretch",
                                            }}
                                          >
                                            <Icon.FiCheck
                                              color="white"
                                              size={20}
                                            />
                                          </div>
                                        }
                                        borderColor="#174A41"
                                        // borderWidth={0}
                                        borderRadius={20}
                                        size={20}
                                        layout="horizontal"
                                        onChange={(checked) => {
                                          let p = {
                                            checked: checked,
                                            value: res,
                                          };
                                          return handleChange2(p);
                                        }}
                                      />
                                      <label
                                        className="mb-0 pb-0"
                                        htmlFor={res.key}
                                      >
                                        {res.name}
                                      </label>
                                    </div>
                                  </>
                                );
                              })}
                            </div>
                          )}
                        </div>

                        <div className="accordion-item">
                          <div
                            className="accordion-title"
                            onClick={() => setIsActive(!isActive)}
                          >
                            <div
                              style={{
                                cursor: "pointer",
                                fontWeight: "bold",
                                fontSize: "18px",
                                color: "#8A00E5",
                              }}
                            >
                              Categories{" "}
                              <span style={{ float: "right" }}>
                                {isActive ? "-" : "+"}
                              </span>
                            </div>
                          </div>
                          {isActive && (
                            <div
                              className="accordion-content mt-2 filter__title"
                              style={{
                                maxHeight: "260px",
                                overflowY: "scroll",
                                padding: "18px",
                                lineHeight: 2,
                              }}
                            >
                              {compTypeList.map((res, index) => {
                                return (
                                  <>
                                    <div key={index}>
                                      <RadioButton
                                        //key={index}
                                        inputId={`Categories_${index}`}
                                        checked={retriveComponentType.includes(
                                          res
                                        )}
                                        value={res}
                                        type="radio"
                                        style={{
                                          overflow: "hidden",
                                          marginRight: "4px",
                                          marginTop: "0px",
                                        }}
                                        icon={
                                          <div
                                            style={{
                                              display: "flex",
                                              flex: 1,
                                              backgroundColor: "#174A41",
                                              alignSelf: "stretch",
                                            }}
                                          >
                                            <Icon.FiCheck
                                              color="white"
                                              size={20}
                                            />
                                          </div>
                                        }
                                        borderColor="#174A41"
                                        // borderWidth={0}
                                        borderRadius={20}
                                        size={20}
                                        layout="horizontal"
                                        //label={res}
                                        onChange={(value) => {
                                          let p = {
                                            checked: value,
                                            value: res,
                                          };
                                          return handleChange(p);
                                        }}
                                      />
                                      <label
                                        className="mb-0 pb-0"
                                        htmlFor={res}
                                      >
                                        {res}
                                      </label>
                                    </div>
                                  </>
                                );
                              })}
                            </div>
                          )}
                          {showFilter && (
                            <div id="filterDiv">{getfilter()}</div>
                          )}

                          {/* <div className="accordion-content mt-2 filter__title">
                                <div
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "15px",
                                    color: "#8A00E5",
                                    marginTop: "2rem",
                                  }}
                                >
                                  Power(MW)
                                </div>
                                <div className="w-14rem mt-2">
                                  <InputText
                                    value={power}
                                    onChange={(e) => setPower(e.target.value)}
                                    className="w-full"
                                  />
                                  <Slider
                                    value={power}
                                    onChange={(e) => setPower(e.value)}
                                    className="w-full"
                                  />

                                </div>
                              </div>

                              <div className="accordion-content mt-2 filter__title">
                                <div
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "15px",
                                    color: "#8A00E5",
                                    marginTop: "2rem",
                                  }}
                                >
                                  Capacity(MW)
                                </div>
                                <div className="w-14rem mt-2">
                                  <InputText
                                    value={capacity}
                                    onChange={(e) => setCapacity(e.target.value)}
                                    className="w-full"
                                  />
                                  <Slider
                                    value={capacity}
                                    onChange={(e) => setCapacity(e.value)}
                                    className="w-full"
                                  />
                                </div>
                              </div> */}
                        </div>
                      </div>
                      {/*
                        <div className="widget-filters__item">
                          <div className="accordion">
                            <div className="accordion-item">
                              <div
                                className="accordion-title"
                                onClick={() =>
                                  setIsActiveSavedComp(!isActiveSavedComp)
                                }
                              >
                                <div
                                  style={{
                                    cursor: "pointer",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Saved Components{" "}
                                  <span style={{ float: "right" }}>
                                    {isActiveSavedComp ? "-" : "+"}
                                  </span>
                                </div>
                              </div>

                              {isActiveSavedComp && projectDetail &&
                                projectDetail.comps.length > 0 && (
                                  <ProjectCategories component={projectDetail} />
                                )}
                            </div>
                          </div>
                                </div>*/}
                    </div>

                    <div
                      style={{
                        alignContent: "center",
                        marginTop: "5%",
                        cursor: "pointer",
                        fontSize: "12px",
                        color: "#8A00E5",
                      }}
                      className="d-flex"
                    >
                      {/* <InputSwitch
                          checked={ResetChecked}
                          onChange={(e) => ResetFilter(e.value)}
                        /> */}
                      <button
                        class="primaryBtn"
                        style={{ width: "50%", height: "5vh" }}
                        onClick={(e) => ResetFilter(e.value)}
                      >
                        Reset Filter
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="block-split__item block-split__item-content col-auto flex-grow-1">
              <div className="block">
                <div className="products-view">
                  <div className="products-view__body">
                    <div className="products-view__loader" />
                    <div className="products-view__options view-options view-options--offcanvas--mobile">
                      <div className="view-options__body mt-0 ml-3">
                        <button
                          type="button"
                          className="view-options__filters-button filters-button"
                        >
                          <span className="filters-button__icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={16}
                              height={16}
                            >
                              <path d="M7 14v-2h9v2H7zm7-7h2v2h-2V7zm-1.5-1c.3 0 .5.2.5.5v3c0 .3-.2.5-.5.5h-2c-.3 0-.5-.2-.5-.5v-3c0-.3.2-.5.5-.5h2zM7 2h9v2H7V2zM5.5 5h-2c-.3 0-.5-.2-.5-.5v-3c0-.3.2-.5.5-.5h2c.3 0 .5.2.5.5v3c0 .3-.2.5-.5.5zM0 2h2v2H0V2zm9 7H0V7h9v2zm-7 5H0v-2h2v2zm1.5-3h2c.3 0 .5.2.5.5v3c0 .3-.2.5-.5.5h-2c-.3 0-.5-.2-.5-.5v-3c0-.3.2-.5.5-.5z" />
                            </svg>
                          </span>
                          <span className="filters-button__title">Filters</span>
                          <span className="filters-button__counter">0</span>
                        </button>

                        <div className="view-options__legend d-flex">
                          <input
                            type="search"
                            style={{ width: "50vw" }}
                            className="form-control ml-4"
                            id="datatable-search-input"
                            onChange={(e) => getSearchResult(e.target.value)}
                            placeholder="   Search Components .."
                          />
                        </div>
                        <div className="view-options__spring d-flex" />
                        <div className="view-option__select mr-3">
                          {isProjectPath == "false" && (
                            <Button
                              label=""
                              icon="pi pi-plus"
                              className="p-button-rounded"
                              style={{ backgroundColor: "#8A00E5" }}
                              onClick={() => onClick("displayCompDialogue", "")}
                              tooltip="Create Component"
                            />
                          )}
                        </div>
                        <div className="view-options__select mr-3">
                          <label htmlFor="view-option-sort">Sort:</label>
                          &nbsp;
                          <select
                            id="view-option-sort"
                            onChange={(e) =>
                              setSelectedOption(e.target.value, "order")
                            }
                          >
                            {orderDropdown.map((data, index) => {
                              return (
                                <option value={data} key={index}>
                                  {data}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        {/* <div className="view-options__select">
                            <label htmlFor="view-option-limit">Show</label>
                            &nbsp;
                            <select
                              id="view-option-limit"
                              onChange={(e) =>
                                setSelectedOption(e.target.value, "size")
                              }
                            >
                              {sizeDropdown.map((data, index) => {
                                return (
                                  <option value={data} key={index}>
                                    {data}
                                  </option>
                                );
                              })}
                            </select>
                          </div> */}
                      </div>
                    </div>

                    <div className="container-fluid">
                      <div className="row">
                        {currentCards.map((comp, index) => {
                          console.log(comp);
                          const showOnCard = showOnCardData(comp.type);
                          //const isAdded =
                          return (
                            <div class="container-fluid">
                              <div class="d-flex justify-content-center row">
                                <div
                                  class="col"
                                  style={{
                                    maxWidth: "83%",
                                    marginBottom: "2%",
                                    marginTop: "1%",
                                  }}
                                >
                                  <div
                                    class="row bg-white border rounded"
                                    onClick={(e) => {
                                      if (
                                        e.target.tagName.toLowerCase() !==
                                          "i" &&
                                        e.target.tagName.toLowerCase() !==
                                          "button"
                                      ) {
                                        debugger;
                                        navigate(
                                          "/ViewCompDetails/" + comp.id,
                                          {
                                            state: {
                                              compData: comp,
                                              Btntype: "normal",
                                              visibility: "true",
                                            },
                                          }
                                        );
                                      }
                                    }}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <div
                                      class="col"
                                      style={{
                                        marginLeft: 0,
                                        marginRight: 0,
                                        marginBottom: 0,
                                        marginTop: 0,
                                        paddingLeft: 0,
                                        paddingRight: 0,
                                      }}
                                    >
                                      {/* <div class="image"> */}
                                      <span class="ds_badge">
                                        <span class="badge badge_siemens me-2 mr-0">
                                          {comp.visible_to_all == true
                                            ? " Released"
                                            : " Prepared"}
                                        </span>
                                      </span>
                                      <img
                                        src="https://esd-api.mosaic-dev.siemens-energy.cloud/api/v1/static/image/card_background.png"
                                        alt="Component"
                                        class="img"
                                        style={{
                                          height: "30vh",
                                          width: "100%",
                                        }}
                                      />
                                      <a
                                        class="mask_parent"
                                        onClick={() =>
                                          navigate(
                                            "/ViewCompDetails/" + comp.id,
                                            {
                                              state: {
                                                compData: comp,
                                                Btntype: "normal",
                                                visibility: "true",
                                              },
                                            }
                                          )
                                        }
                                      >
                                        <div
                                          class="mask position-relative"
                                          style={{
                                            backgroundColor:
                                              "rgba(251, 251, 251, 0.15)",
                                          }}
                                        ></div>
                                        <span class="round-span-2"></span>
                                        <span class="round-span">
                                          {comp.type == "Heat Pump"
                                            ? "HP"
                                            : comp.type == "Li-Ion Battery"
                                            ? "LI"
                                            : comp.type == "Electrolyser"
                                            ? "E-lyser"
                                            : comp.type == "Photovoltaics"
                                            ? "PH"
                                            : ""}
                                        </span>
                                      </a>
                                    </div>
                                    <div class="col-md-6 mt-3 mb-1">
                                      <h5 class="card-title ds_card_title">
                                        {comp.type}
                                      </h5>
                                      <p>
                                        <small class="text-muted">
                                          {comp.id}
                                        </small>
                                      </p>

                                      {/* <h5 class="card-title" style={{ color: "#262626" }}>
                                            {comp.type}
                                          </h5> */}
                                      {showOnCard != ""
                                        ? showOnCard.map((item, index) => {
                                            const section = item.section;
                                            const key = item.key;
                                            const name = item.name;
                                            const value =
                                              comp.sections[section][key];

                                            return (
                                              <div>
                                                {name}: {value}
                                              </div>
                                            );
                                          })
                                        : ""}
                                      <div
                                        style={{
                                          position: "absolute",
                                          left: 0,
                                          bottom: 0,
                                        }}
                                      >
                                        <small class="text-muted ml-3">
                                          <i
                                            class="fa fa-user mr-1"
                                            aria-hidden="true"
                                          ></i>
                                          {comp.created_by
                                            ? comp.created_by.match(
                                                /^([^@]*)@/
                                              )[1]
                                            : ""}
                                        </small>
                                        <small class="text-muted ml-3">
                                          <i
                                            class="fa fa-calendar mr-1"
                                            aria-hidden="true"
                                          ></i>
                                          {
                                            new Date(comp.created_at)
                                              .toISOString()
                                              .split("T")[0]
                                          }
                                        </small>
                                      </div>
                                    </div>
                                    <div
                                      class="align-items-center align-content-center col-md-3 mt-1"
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      <div
                                        style={{
                                          position: "absolute",
                                          top: 0,
                                          right: 3,
                                        }}
                                      >
                                        {(() => {
                                          debugger;
                                          const sections =
                                            comp.sections[
                                              "General Information"
                                            ];
                                          const keys = Object.keys(sections);
                                          const countryKey = keys.find((key) =>
                                            key.includes("country")
                                          );
                                          const country = sections[countryKey];
                                          return (
                                            <>
                                              <small class="text-muted">
                                                <i
                                                  class="fa fa-map-marker me-2 mr-1"
                                                  aria-hidden="true"
                                                ></i>
                                                {country}
                                              </small>
                                            </>
                                          );
                                        })()}
                                        {/* {comp.sections["General Information"][
                                          "hi_gi_country"
                                        ] ? (
                                          <small class="text-muted">
                                            <i
                                              class="fa fa-map-marker me-2 mr-1"
                                              aria-hidden="true"
                                            ></i>
                                            {
                                              comp.sections[
                                                "General Information"
                                              ]["hi_gi_country"]
                                            }
                                          </small>
                                        ) : (
                                          ""
                                        )} */}
                                      </div>
                                      <div
                                        style={{
                                          position: "absolute",
                                          bottom: 0,
                                          right: 3,
                                        }}
                                      >
                                        {/* class="d-flex flex-row align-items-left"> */}
                                        {!params.name && (
                                          <a
                                            type="submit"
                                            className="text-siemens mt-3 mb-auto"
                                            style={{ cursor: "pointer" }}
                                            onClick={(e) => {
                                              if (
                                                e.target.tagName.toLowerCase() ==
                                                "i"
                                              ) {
                                                confirmDialog({
                                                  message:
                                                    "Are you sure you want to delete this component?",
                                                  header: "Confirmation",
                                                  icon: "pi pi-info-circle",
                                                  acceptClassName:
                                                    "p-button-danger",
                                                  position: "top",
                                                  accept: () => {
                                                    deleteComponent(comp.id);
                                                  },
                                                  //reject,
                                                });
                                              }
                                            }}
                                          >
                                            <i
                                              class="fa fa-trash fa-lg ml-1"
                                              title="Delete from Database"
                                              style={{ color: "red" }}
                                            ></i>
                                          </a>
                                        )}
                                        {isProjectPath == "false" && (
                                          <a
                                            type="submit"
                                            className="text-siemens ml-2 mt-3 mb-auto mr-1"
                                            style={{ cursor: "pointer" }}
                                            onClick={(e) => {
                                              if (
                                                e.target.tagName.toLowerCase() ===
                                                "i"
                                              ) {
                                                navigate(
                                                  "/ViewCompDetails/" + comp.id,
                                                  {
                                                    state: {
                                                      compData: comp,
                                                      Btntype: "copy",
                                                      visibility: "true",
                                                    },
                                                  }
                                                );
                                              }
                                            }}
                                          >
                                            <i
                                              class="fa fa-copy fa-lg ml-1"
                                              title="Copy Component"
                                            ></i>
                                          </a>
                                        )}
                                      </div>
                                      <div
                                        class="d-flex flex-column mt-4"
                                        style={{
                                          display: "flex", // Add this line to make the div a flex container
                                          alignItems: "center", // Add this line to center the button vertically
                                        }}
                                      >
                                        {params.name && (
                                          <div
                                          //class="mt-3"
                                          >
                                            {addedStatus[comp.id] == true ? (
                                              <Button
                                                type="submit"
                                                className=" primaryBtn" // ml-1 d-flex"
                                                style={{
                                                  width: "12vw",
                                                  backgroundColor: "red",
                                                  display: "inline-block",
                                                }}
                                                disabled={
                                                  loadingStatus[comp.id]
                                                }
                                                onClick={(e) => {
                                                  if (
                                                    e.target.tagName.toLowerCase() ==
                                                    "button"
                                                  ) {
                                                    e.stopPropagation();
                                                    handleCart(e, comp, "");
                                                  }
                                                }}
                                              >
                                                Remove
                                              </Button>
                                            ) : (
                                              <Button
                                                type="submit"
                                                className=" primaryBtn" //ml-1 mb-2 mr-1 d-flex"
                                                style={{
                                                  width: "12vw",
                                                  backgroundColor: "#1b1532",
                                                }}
                                                disabled={
                                                  loadingStatus[comp.id]
                                                }
                                                onClick={(e) => {
                                                  if (
                                                    e.target.tagName.toLowerCase() ==
                                                    "button"
                                                  ) {
                                                    handleCart(e, comp, "");
                                                  }
                                                }}
                                              >
                                                {" "}
                                                Add
                                              </Button>
                                            )}
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="products-view__pagination"> */}

                <Paginator
                  first={indexOfFirstCard}
                  rows={cardsPerPage}
                  totalRecords={componentList.length}
                  //rowsPerPageOptions={[cardsPerPage]}
                  onPageChange={onPageChange}
                />
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
