import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";
import { Rating } from "primereact/rating";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import axios from "axios";
import "./Project.css";
import ProjectDetailModel from "./ProjectDetailModel";
import ProjectUpload from "./ProjectUpload";
import { useDispatch } from "react-redux";
import { clearCart } from "../../redux/actions/index";
import { saveAs } from "file-saver";
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Tooltip } from "primereact/tooltip";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";

export function createId() {
  let id = "";
  let chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for (let i = 0; i < 10; i++) {
    id += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return "project-" + id;
}

const Project = () => {
  let emptyProduct = {
    id: null,
    name: "",
    created_by: null,
  };
  const navigate = useNavigate();
  debugger;
  const access_token = localStorage.getItem("accessToken");

  const [products, setProducts] = useState(null);
  const [productDialog, setProductDialog] = useState(false);
  const [deleteProductDialog, setDeleteProductDialog] = useState(false);
  const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
  const [product, setProduct] = useState(emptyProduct);
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilterValue] = useState('');
  const toast = useRef(null);
  const dt = useRef(null);
  const [displayBasic, setDisplayBasic] = useState(false);
  const [displayProjectUplaod, setdisplayProjectUplaod] = useState(false);
  const userRole = localStorage.getItem("userRole");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    createdBy: { value: null, matchMode: FilterMatchMode.STARTS_WITH }    
});

const onGlobalFilterChange = (e) => {
  const value = e.target.value;
  let _filters = { ...filters };

  _filters['global'].value = value;

  setFilters(_filters);
  setGlobalFilterValue(value);
};
  const dispatch = useDispatch();
  debugger;

  var userRoleList = userRole.split(",");
  var adminUser = userRoleList.indexOf("ADMIN");
  if (adminUser > 0) {
    localStorage.setItem("AdminUser", true);
  }

  useEffect(() => {
    console.log(localStorage.getItem("accessToken"));
    if (
      localStorage.getItem("accessToken") != null &&
      localStorage.getItem("accessToken") != ""
    ) {
      getProjects();
      localStorage.removeItem("cart");
      localStorage.removeItem("project");
      dispatch(clearCart());
    } else {
      navigate("/login");
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const dialogFuncMap = {
    displayBasic: setDisplayBasic,
    displayProjectUplaod: setdisplayProjectUplaod,
  };

  const getProjects = () => {
    debugger;
    console.log(access_token);
    const token = access_token;
    //integerating API
    axios
      .get(
        "https://esd-api.mosaic-dev.siemens-energy.cloud/api/v1/dev/project/get-project-user?" +
          "&verbose_comp=" +
          true +
          "&sverbose_note=" +
          false +
          "&page=" +
          1 +
          "&size=" +
          20 +
          "&order=" +
          false,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((res) => {
        setProducts(res.data);
      });
  };

  const formatCurrency = (value) => {
    return value.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  };

  const openNew = () => {
    emptyProduct.name = createId();
    setProduct(emptyProduct);
    setSubmitted(false);
    setProductDialog(true);
  };

  const hideDialog = () => {
    setSubmitted(false);
    setProductDialog(false);
  };

  const hideDeleteProductDialog = () => {
    setDeleteProductDialog(false);
  };

  const hideDeleteProductsDialog = () => {
    setDeleteProductsDialog(false);
  };

  const saveProduct = () => {
    setSubmitted(true);

    if (product.name.trim()) {
      if (product.id) {
        const token = access_token;
        axios
          .put(
            "https://esd-api.mosaic-dev.siemens-energy.cloud/api/v1/dev/project/update-project/" +
              product.id,
            {
              project: {
                name: product.name,
                comps: [],
                notes: [],
              },
            },
            { headers: { Authorization: `Bearer ${token}` } }
          )
          .then((response) => {
            getProjects();
            toast.current.show({
              severity: "success",
              summary: "Successful",
              detail: "Project Updated",
              life: 3000,
            });
          });
      } else {
        const token = access_token;

        axios
          .post(
            "https://esd-api.mosaic-dev.siemens-energy.cloud/api/v1/dev/project/create-new-project",
            {
              new_project: {
                name: product.name,
                comps: [],
                notes: [],
              },
            },
            { headers: { Authorization: `Bearer ${token}` } }
          )
          .then((response) => {
            getProjects();
            toast.current.show({
              severity: "success",
              summary: "Successful",
              detail: "Project Created",
              life: 3000,
            });
          });
      }

      // setProducts(_products);
      setProductDialog(false);
      setProduct(emptyProduct);
    }
  };

  const editProduct = (product) => {
    debugger;
    console.log(product);
    setProduct({ ...product });
    setProductDialog(true);
  };

  const confirmDeleteProduct = (product) => {
    setProduct(product);
    setDeleteProductDialog(true);
  };

  const deleteProduct = () => {
    const token = access_token;
    axios
      .delete(
        "https://esd-api.mosaic-dev.siemens-energy.cloud/api/v1/dev/project/delete-project/" +
          product.id,

        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        console.log(response);
        getProjects();
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Project Deleted",
          life: 3000,
        });
      });
    // let _products = products.filter(val => val.id !== product.id);
    // setProducts(_products);
    setDeleteProductDialog(false);
    setProduct(emptyProduct);
  };

  // eslint-disable-next-line
  const findIndexById = (id) => {
    let index = -1;
    for (let i = 0; i < products.length; i++) {
      if (products[i].id === id) {
        index = i;
        break;
      }
    }

    return index;
  };

  const createId = () => {
    let id = "";
    let chars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (let i = 0; i < 10; i++) {
      id += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return "project-" + id;
  };

  // eslint-disable-next-line
  const confirmDeleteSelected = () => {
    setDeleteProductsDialog(true);
  };

  const deleteSelectedProducts = () => {
    let _products = products.filter((val) => !selectedProducts.includes(val));
    setProducts(_products);
    setDeleteProductsDialog(false);
    setSelectedProducts(null);
    toast.current.show({
      severity: "success",
      summary: "Successful",
      detail: "Products Deleted",
      life: 3000,
    });
  };
  // eslint-disable-next-line
  const onCategoryChange = (e) => {
    let _product = { ...product };
    _product["category"] = e.value;
    setProduct(_product);
  };

  const onBasicUploadAuto = ({ files }) => {
    debugger;

    const [file] = files;
    const fileReader = new FileReader();
    fileReader.onload = (e) => {
      uploadProject(e.target.result);
    };
    fileReader.readAsDataURL(file);
  };

  const uploadProject = async (projectFile) => {
    debugger;
    var myHeaders = new Headers();
    myHeaders.append("Authorization", localStorage.getItem("accessToken"));

    let formData = new FormData();
    formData.append("scenario_input", projectFile);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formData,
      redirect: "follow",
      crossDomain: true,
    };
    // eslint-disable-next-line
    const response = await fetch(
      "https://ul4iuok3t6.execute-api.eu-central-1.amazonaws.com/v1/esd-upload-excel",
      requestOptions
    )
      .then((response) => response.json())
      .then((responseJson) => {
        debugger;
        console.log(responseJson);
      })
      .then((result) => console.log(result))
      .catch((error) => console.log("error", error));
  };

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _product = { ...product };
    _product[`${name}`] = val;

    setProduct(_product);
  };
  // eslint-disable-next-line
  const onInputNumberChange = (e, name) => {
    const val = e.value || 0;
    let _product = { ...product };
    _product[`${name}`] = val;

    setProduct(_product);
  };

  const projectUpload = () => {
    setdisplayProjectUplaod(true);
  };

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <Button
          label="Create"
          icon="pi pi-plus"
          className="primaryBtn"
          onClick={openNew}
        />
        <span></span>
        {/* disabled upload functionlity starts here
          <Button
          label="Upload"
          icon="pi pi-plus"
          className="primaryBtn"
          onClick={projectUpload}
        />
        disabled upload functionlity ends here */}

        {/*
        <FileUpload
          mode="basic"
          name="demo[]"
          url="https://ul4iuok3t6.execute-api.eu-central-1.amazonaws.com/v1/esd-upload-excel%22"
          accept="xlsx/*"
          maxFileSize={1000000}
          onUpload={onBasicUploadAuto}
          auto
          chooseLabel="Upload Projects"
          className="primaryBtn"
    />*/}
      </React.Fragment>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        {/* <FileUpload mode="basic" name="demo[]" auto url="https://primefaces.org/primereact/showcase/upload.php" accept=".csv" chooseLabel="Import" className="mr-2 inline-block" onUpload={importCSV} />
                <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} /> */}
      </React.Fragment>
    );
  };
  // eslint-disable-next-line
  const imageBodyTemplate = (rowData) => {
    return (
      <img
        src={`images/product/${rowData.image}`}
        onError={(e) =>
          (e.target.src =
            "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
        }
        alt={rowData.image}
        className="product-image"
      />
    );
  };
  // eslint-disable-next-line
  const priceBodyTemplate = (rowData) => {
    return formatCurrency(rowData.price);
  };
  // eslint-disable-next-line
  const ratingBodyTemplate = (rowData) => {
    return <Rating value={rowData.rating} readOnly cancel={false} />;
  };
  // eslint-disable-next-line
  const statusBodyTemplate = (rowData) => {
    return (
      <span
        className={`product-badge status-${rowData.inventoryStatus.toLowerCase()}`}
      >
        {rowData.inventoryStatus}
      </span>
    );
  };

  const showFilterDetails = (rowData) => {
    
    navigate("/dashboard/" + rowData.id);
  };

  const onClick = (name, compDetail) => {
    setProduct(compDetail);
    dialogFuncMap[`${name}`](true);
  };

  //added by Neha...
  const downloadProject = (projectId) => {
    console.log(access_token);
    const token = access_token;
    debugger;
    axios
      .get(
        "https://esd-api.mosaic-dev.siemens-energy.cloud/api/v1/dev/projectV2/download_project?id="+
          projectId,

        {
          headers: { Authorization: `Bearer ${token}` },
          responseType: "arraybuffer",
        }
      )
      .then((response) => {
        const blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(blob, "pToX.xlsx");
      });
  };

  const nameTemplate = (value) => {
    debugger;
    return value.created_by.split("@")[0].replace(/ext/, "").toUpperCase();
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        {/*
        <Button
          icon="pi pi-eye"
          tooltip="View Project" 
          tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 10 }}
          className="p-button-rounded p-button-secondary mr-2"
          onClick={() => showFilterDetails(rowData)}
          ></Button> */}

        <Button
          className="p-button-rounded mr-2"
          tooltip="Download Details"
          tooltipOptions={{
            position: "bottom",
            mouseTrack: true,
            mouseTrackTop: 10,
          }}
          style={{ backgroundColor: "#6366f1" }}
          icon="pi pi-download"
          iconPos="right"
          onClick={() => downloadProject(rowData.id)}
        ></Button>
        <Button
          icon="pi pi-trash"
          tooltip="Delete Project"
          tooltipOptions={{
            position: "bottom",
            mouseTrack: true,
            mouseTrackTop: 10,
          }}
          className="p-button-rounded p-button-warning mr-2"
          style={{ backgroundColor: "red" }}
          onClick={() => confirmDeleteProduct(rowData)}
        />
        <Button
          icon="pi pi-window-maximize"
          className="p-button-rounded p-button-primary"
          tooltip="View Details"
          tooltipOptions={{ position: "top" }}
          onClick={() => onClick("displayBasic", rowData)}
        ></Button>
      </React.Fragment>
    );
  };

  const onHide = (name) => {
    dialogFuncMap[`${name}`](false);
  };

  const header = (
    <div className="table-header">
      <h5 className="mx-0 my-1">My Projects</h5>
      <div
        style={{
          alignSelf: "end",
          marginLeft: "auto",
          justifyContent: "space-between",
        }}
      >
        <React.Fragment>
          <Button
            label="Create"
            icon="pi pi-plus"
            className="primaryBtn ml-3 mr-auto"
            onClick={openNew}
            style={{ marginLeft: "10",width:"95%" }}
          />
          <span></span>
          {/* <Button
            label="Upload"
            icon="pi pi-plus"
            className="primaryBtn ml-3 mr-auto"
            onClick={projectUpload}
          /> */}
        </React.Fragment>
      </div>

      {/* <span className="p-input-icon-left ml-3">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => setGlobalFilterValue(e.target.value)}
          placeholder="Search..."
        />
      </span> */}
      
            <div className="flex justify-content-end">
                <span className="p-input-icon-left ml-4">
                    <i className="pi pi-search" />
                    <InputText value={globalFilter} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
                </span>
            </div>
        
    </div>
  );
  const productDialogFooter = (
    <React.Fragment>
      <Button
        label="Cancel"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDialog}
      />
      <Button
        label="Save"
        icon="pi pi-check"
        className="p-button-text"
        onClick={saveProduct}
      />
    </React.Fragment>
  );
  const deleteProductDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeleteProductDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteProduct}
      />
    </React.Fragment>
  );
  const deleteProductsDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeleteProductsDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteSelectedProducts}
      />
    </React.Fragment>
  );

  return (
    <div className="datatable-crud-demo">
      <Toast ref={toast} />
      <ProjectDetailModel
        visibility={displayBasic}
        compData={product}
        onHide={onHide}
      />

      <ProjectUpload visibility={displayProjectUplaod} onHide={onHide} />

      <div className="card">
        <DataTable
          ref={dt}
          value={products}
          selectionMode="single"
          selection={selectedProducts}
          onSelectionChange={(e) => showFilterDetails(e.value)}
          dataKey="id"
          paginator
          rows={20}
          filters={filters}
          rowsPerPageOptions={[5, 10, 25]}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
          globalFilterFields={['name', 'createdBy']}
          header={header}
          responsiveLayout="scroll"
        >
          {/*
          <Column
            selectionMode="multiple"
            headerStyle={{ width: "3rem" }}
            exportable={false}
            selection={selectedProducts}
            onSelectionChange={(e) => setSelectedProducts(e.value)}
          ></Column>
  */}

          <Column
            field="name"
            header="Name"
            sortable filter
            style={{ minWidth: "7rem" }}
            onClick={() => showFilterDetails()}
          ></Column>
          <Column
            body={(data, props) => (
              <i
                class="fa fa-edit"
                title="Rename Project"
                onClick={(e) => { debugger;
                  e.stopPropagation();  
                  if (e.target.tagName.toLowerCase() == "i") {
                    editProduct(data);
                  }
                }}
              ></i>
            )}
          ></Column>
          <Column
            //field="created_by"
            header="Created By"
            filterField="createdBy"
            body={nameTemplate}
            sortable
            style={{ minWidth: "16rem" }}
          ></Column>
          <Column
            field="created_at"
            header="Created At"
            sortable
            style={{ minWidth: "16rem" }}
          ></Column>
          <Column
            body={actionBodyTemplate}
            exportable={false}
            style={{ minWidth: "8rem" }}
          ></Column>
        </DataTable>
      </div>

      <Dialog
        visible={productDialog}
        style={{ width: "450px" }}
        header="Project Name"
        modal
        className="p-fluid"
        footer={productDialogFooter}
        onHide={hideDialog}
      >
        {product.image && (
          <img
            src={`images/product/${product.image}`}
            onError={(e) =>
              (e.target.src =
                "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
            }
            alt={product.image}
            className="product-image block m-auto pb-3"
          />
        )}
        <div className="field mt-2">
          <InputText
            id="name"
            value={product.name}
            onChange={(e) => onInputChange(e, "name")}
            required
            autoFocus
            className={classNames({ "p-invalid": submitted && !product.name })}
          />
          {submitted && !product.name && (
            <small className="p-error">Name is required.</small>
          )}
        </div>
      </Dialog>

      <Dialog
        visible={deleteProductDialog}
        style={{ width: "450px" }}
        header="Confirm"
        modal
        footer={deleteProductDialogFooter}
        onHide={hideDeleteProductDialog}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {product && (
            <span>
              Are you sure you want to delete <b>{product.name}</b>?
            </span>
          )}
        </div>
      </Dialog>

      <Dialog
        visible={deleteProductsDialog}
        style={{ width: "450px" }}
        header="Confirm"
        modal
        footer={deleteProductsDialogFooter}
        onHide={hideDeleteProductsDialog}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {product && (
            <span>Are you sure you want to delete the selected products?</span>
          )}
        </div>
      </Dialog>
    </div>
  );
};

export default Project;
