import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { Password } from 'primereact/password';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import { classNames } from 'primereact/utils';
import '../CompForm/FormDemo.css';
import { FileUpload } from "primereact/fileupload";
import axios from 'axios';

const ProjectUpload = (props) => {
  const access_token = localStorage.getItem('accessToken'); 
  const [formData, setFormData] = useState({});    
  const [showMessage, setShowMessage] = useState(false);
  const [name,setName]=useState('');
  const { control, register,formState: { errors }, handleSubmit,  reset } = useForm({ 
    mode: "onBlur", });

  const [VisibleToAll,setVisibleToAll]=useState(false);
  const [selectedFile,setSelectedFile]=useState(null);

  
  useEffect(()=>{debugger;
    setFormData({});
    reset();
    },[])

    const VisibleToAllOptions = [
        {label: 'Yes', value: 'True'},
        {label: 'No', value: 'False'}        
    ];
    
    const handleFileChnage=(event)=>{
      setSelectedFile(event.target.files[0]);
    }
    const handleUpload=()=>{debugger;
      const formdata=new FormData();

      formdata.append("visible_to_all", "false");
      formdata.append("user_email", localStorage.getItem("loggedInUser"));
      formdata.append("project_title", name);
      formdata.append("scenario_input",  selectedFile);

      var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
      };      
      
    }

    const onSubmit = (data) => {debugger;        
      if(!selectedFile){
        console.log("No File Selected");
        return;
      }
      const token = access_token;          
            axios.put(
                "https://ul4iuok3t6.execute-api.eu-central-1.amazonaws.com/v1/esd-upload-excel/",
                     {
                        "user_email": localStorage.getItem("loggedInUser"),
                          "project_title":name,
                          "visible_to_all":false,
                          "scenerio_input":selectedFile,

                    },
                        { headers: {  },'content-type': 'text/json' },
                      )
                      .then((response) => {
                        setShowMessage(true);
                        console.log(response);                                       
                      })
                      .catch((error) => console.log("error", error));

    };

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };

  return props.visibility ? (
    <>
      <Dialog
        header={"Project Upload"}
        visible={props.visibility}
        style={{ width: "40vw" }}
        onHide={() => props.onHide("displayProjectUplaod")}
      >

       <div className="form-demo">
            <div className="flex justify-content-left pl-0">
                <div className="card pt-5" >
                    {/*<h5 className="text-center">Component Details</h5>*/}
                    <form key={1} onSubmit={handleSubmit(onSubmit)} className="p-fluid">                        
                                           
                    <div className="field">
                            <span className="p-float-label">
                            <Controller name="ProjectTitle"  control={control}  
                            rules={{ required: true}}   
                            defaultValue="" render={({ field, fieldState }) => (
                            <InputText id={field.name} {...register(field.name)} {...field} 
                            value={name}  onChange={(e) => setName(e.target.value)} ></InputText>
                            )} />
                            <label htmlFor="ProjectTitle" className={classNames({ 'p-error': errors.name })}>Project Title*</label>
                            </span>
                            {getFormErrorMessage('Please enter Project Name')}
                        </div>

                        <div className="field pt-3">
                            <span className="p-float-label">
                                <Controller name="VisibleToAll" control={control} render={({ field }) => (
                                    <Dropdown value={VisibleToAll} options={VisibleToAllOptions} onChange={(e) => setVisibleToAll(e.value)}  ></Dropdown>                                   
                                )} />
                                <label htmlFor="VisibleToAll">Visible To All</label>
                            </span>
                        </div>

                        <div className="field">
                            <input type="file" onChange={handleFileChnage} />
                           {/* <button className="primaryBtn" onClick={handleUpload}>Upload</button>*/}
                        </div>
                        <Button className="primaryBtn" onClick={onSubmit}>Upload</Button>
                        
                        </form> 
                    </div>
                </div>
        </div> 
                
       </Dialog>
    </>
  ) : null;
};

export default ProjectUpload;



